<template>
	<form @submit.prevent="checkValidation() && saveBankAccount()">
		<div class="idb-block">
			<div class="idb-block-title">
				<h2>
					{{status}} Bank Account
					<help-icon :docPath="docPath" />
				</h2>
			</div>

			<div class="idb-block-content">
				<div
					class="alert alert-warning"
					v-if="bankAccount.isItemActioned"
				>This bank account has a pending action against it and cannot be edited</div>
				<div class="alert alert-warning" v-if="deleteCalledWithError">
					You cannot delete this bank account as it is linked to the following groups
					<ul>
						<li v-for="group in linkedGroups" :key="group">{{group}}</li>
					</ul>
				</div>
				<!-- Reference -->
				<div class="form-group row" :class="{ invalid: $v.bankAccount.reference.$error }">
					<label for="bankReferenceInput" class="col-form-label col-md-3 required">Reference</label>
					<div class="col-md-6">
						<input
							id="bankReferenceInput"
							v-focus
							type="text"
							class="form-control"
							v-model.trim="$v.bankAccount.reference.$model"
							:disabled="bankAccount.isItemActioned"
							:readonly="!canManageBanks"
						/>
						<!-- Validation -->
						<validation-messages v-model="$v.bankAccount.reference" name="reference"></validation-messages>
					</div>
				</div>
				<!-- Description -->
				<div class="form-group row" :class="{ invalid: $v.bankAccount.description.$error }">
					<label for="bankDescriptionInput" class="col-form-label col-md-3">Description</label>
					<div class="col-md-6">
						<textarea id="bankDescriptionInput"
							class="form-control"
							rows="3"
							v-model.trim="$v.bankAccount.description.$model"
							:disabled="bankAccount.isItemActioned"
							:readonly="!canManageBanks"
						></textarea>
						<!-- Validation -->
						<validation-messages v-model="$v.bankAccount.description"></validation-messages>
					</div>
				</div>
				<!-- Sort Code -->
				<div class="form-group row" :class="{ invalid: $v.bankAccount.sortCode.$error }">
					<label for="sortCodeInput" class="label-control col-md-3 required">Sort Code</label>
					<div class="col-md-3">
						<the-mask id="sortCodeInput"
							type="text"
							class="form-control"
							v-model="$v.bankAccount.sortCode.$model"
							:mask="['##-##-##']"
							:guide="true"
							ref="sortCode"
							:disabled="bankAccount.isItemActioned"
							:readonly="!canManageBanks"
						/>
						<!-- Validation -->
						<validation-messages v-model="$v.bankAccount.sortCode" name="sort code"></validation-messages>
					</div>
					<div v-if="bankAccountValidation != null">
						<div
							v-if="bankAccountValidation.valid || bankAccountValidation.errorCode === '1502'"
							class="col-md-1"
						>
							<span style="font-size: 200%; color: green" class="fa fa-check-circle"></span>
						</div>
						<div v-else class="col-md-1">
							<span class="text-warning small text-nowrap">{{bankAccountValidation.errorText}}</span>
						</div>
					</div>
				</div>
				<!-- Account Number -->
				<div class="form-group row" :class="{ invalid: $v.bankAccount.accountNumber.$error }">
					<label for="accountNumberInput" class="label-control col-md-3 required">Account Number</label>
					<div class="col-md-3">
						<the-mask id="accountNumberInput"
							type="text"
							class="form-control"
							v-model="$v.bankAccount.accountNumber.$model"
							:mask="['########']"
							:guide="false"
							ref="accountNumber"
							:disabled="bankAccount.isItemActioned"
							:readonly="!canManageBanks"
						/>
						<validation-messages v-model="$v.bankAccount.accountNumber" name="account number"></validation-messages>
					</div>
					<div v-if="bankAccountValidation != null">
						<div v-if="bankAccountValidation.valid" class="col-md-1">
							<span style="font-size: 200%; color: green" class="fa fa-check-circle"></span>
						</div>
						<div v-else-if="bankAccountValidation.errorCode === '1502'" class="col-md-1">
							<span class="text-warning small text-nowrap">{{bankAccountValidation.errorText}}</span>
						</div>
					</div>
				</div>

				<!-- Currency -->
				<div class="form-group row">
					<label class="label-control col-md-3">Currency</label>
					<div class="col-md-3">
						<div class="form-control-plaintext">
							<span style="margin-right:5px;">{{$v.bankAccount.currency.$model}}</span>
						</div>
					</div>
				</div>
				<!-- Intermediate Bank Account Id -->
				<div class="form-group row">
					<label for="bankIdInput" class="label-control col-md-3">Intermediate Bank Id</label>
					<div class="col-md-3">
						<input id="bankIdInput"
							type="text"
							class="form-control"
							v-model.trim="$v.bankAccount.intermediateBankId.$model"
							:disabled="bankAccount.isItemActioned"
							:readonly="!canManageBanks"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="idb-block">
			<div class="idb-block-title">
				<h2>Bank Name and Address</h2>
			</div>
			<div class="idb-block-content">
				<!-- Bank Name -->
				<div class="form-group row">
					<label for="bankNameInput" class="col-form-label col-md-3">Bank Name</label>
					<div class="col-md-6">
						<input id="bankNameInput"
							class="form-control"
							v-model.trim="bankAccount.bankName"
							:disabled="bankAccount.isItemActioned"
							:readonly="!canManageBanks"
						/>
					</div>
				</div>
				<!-- Address -->
				<div class="form-group row">
					<label for="address1Input" class="col-form-label col-md-3">Address</label>
					<div class="col-md-6">
						<input id="address1Input"
							class="form-control"
							v-model.trim="bankAccount.address1"
							:disabled="bankAccount.isItemActioned"
							:readonly="!canManageBanks"
						/>
						<input id="address2Input"
							class="form-control"
							v-model.trim="bankAccount.address2"
							:disabled="bankAccount.isItemActioned"
							:readonly="!canManageBanks"
						/>
						<input id="address3Input"
							class="form-control"
							v-model.trim="bankAccount.address3"
							:disabled="bankAccount.isItemActioned"
							:readonly="!canManageBanks"
						/>
						<input id="address4Input"
							class="form-control"
							v-model.trim="bankAccount.address4"
							:disabled="bankAccount.isItemActioned"
							:readonly="!canManageBanks"
						/>
						<input id="address5Input"
							class="form-control"
							v-model.trim="bankAccount.address5"
							:disabled="bankAccount.isItemActioned"
							:readonly="!canManageBanks"
						/>
					</div>
				</div>
				<!-- Postcode -->
				<div class="form-group row">
					<label for="bankPostcodeInput" class="col-form-label col-md-3">Post Code</label>
					<div class="col-md-6">
						<input id="bankPostcodeInput"
							class="form-control"
							v-model.trim="bankAccount.postcode"
							:disabled="bankAccount.isItemActioned"
							:readonly="!canManageBanks"
						/>
					</div>
				</div>
				<!-- Country -->
				<div class="form-group row">
					<label for="countryInput" class="col-form-label col-md-3">Country</label>
					<div class="col-md-6">
						<b-form-select id="countryInput"
							v-model="bankAccount.country"
							:options="countries"
							text-field="description"
							value-field="code"
							:disabled="bankAccount.isItemActioned || !canManageBanks"
						></b-form-select>
					</div>
				</div>
			</div>
			<div class="idb-block-footer">
				<button id="saveEditButton"
					class="btn btn-primary"
					:disabled="bankAccount.isItemActioned || isLoading"
					type="submit"
					v-if="canManageBanks"
				>{{ status === "Edit" ? "Save" : status }}</button>
				<button id="cancelButton" class="btn btn-outline-danger pull-right ml-3" type="button" @click="back">Cancel</button>

				<button id="deleteButton"
					v-if="canManageBanks && status === 'Edit'"
					class="btn btn-danger pull-right"
					@click="deleteBankAccount"
					:disabled="bankAccount.isItemActioned || isLoading"
					type="button"
				>
					<i class="glyphicon ti-trash rpad"></i>Delete Bank Account
				</button>
			</div>
		</div>
	</form>
</template>

<script>
import _ from 'lodash'
import DataLeaveMixin from '@/Assets/Mixins/DataLeaveMixin'
import { TheMask } from 'vue-the-mask'
import {
	required,
	maxLength,
	numeric
} from 'vuelidate/lib/validators'
import { exactLength } from '@/Assets/Validators'
import swal from 'sweetalert2'
import axios from 'axios'
import roles from '@/Assets/Constants/roles'
import loading from '@/Assets/Mixins/LoadingMixin'
import { mapGetters } from 'vuex'
import colours from '@/Assets/Constants/colours'

export default {
	mixins: [DataLeaveMixin, loading],
	props: ['status', 'id'],
	computed: {
		canManageBanks () {
			return this.$store.getters.isInRole(roles.ManageBanks)
		},
		docPath () {
			switch (this.status) {
				case 'Create':
					return '/administration/bankaccounts/createbankaccount/'
				case 'Edit':
					return '/administration/bankaccounts/editbankaccount/'
				default:
					return null
			}
		},
		...mapGetters(['selectedCustomer'])
	},
	watch: {
		'bankAccount.sortCode': function () {
			this.digitsOrDashes()
		},
		'bankAccount.accountNumber': function () {
			this.digitsOrDashes()
		},
		selectedCustomer () { this.$router.push({ name: 'BankAccounts' }) }
	},
	components: {
		TheMask
	},
	data () {
		return {
			currencies: ['GBP', 'USD', 'EUR'],
			countries: [],
			bankAccount: {
				reference: '',
				description: null,
				accountType: 'UK Domestic',
				sortCode: '',
				accountNumber: '',
				bankIdentifier: null,
				bic: null,
				iban: null,
				currency: 'GBP',
				bankName: null,
				address1: null,
				address2: null,
				address3: null,
				address4: null,
				address5: null,
				country: 'GB',
				postcode: null
			},
			bankAccountValidation: null,
			linkedToGroups: false,
			linkedGroups: [],
			deleteCalledWithError: false
		}
	},
	async mounted () {
		var countryResponse = await axios.get(
			`${process.env.VUE_APP_PLATFORM_API_URL}Lookup/Countries`,
			{ showload: true }
		)
		this.countries = countryResponse.data
		if (this.status === 'Edit') {
			await this.loadBankAccount()
			await this.loadBankAccountGroups()
		}
	},
	methods: {
		async saveBankAccount () {
			try {
				this.deleteCalledWithError = false
				if (this.status === 'Create') {
					this.bankAccount.paygateId = this.$store.state.common.paygateId
					const response = await axios.post(
						`${process.env.VUE_APP_PLATFORM_API_URL}BankAccounts`,
						this.bankAccount,
						{ showload: true, showerror: true, errormessage: 'Bank Account failed to save' }
					)
					this.$v.$reset()

					this.$v.$reset()
					if (response.data.status === 'Action') {
						this.$toastr.s(
							'New bank account needs to be approved',
							'Needs Approval'
						)
						this.$router.push({ name: 'BankAccounts' })
					} else {
						this.$toastr.s('New bank account created', 'Created')
						this.$router.push({ name: 'BankAccounts' })
					}
				} else {
					const response = await axios.put(
						`${process.env.VUE_APP_PLATFORM_API_URL}BankAccounts`,
						this.bankAccount,
						{ showload: true, showerror: true, errormessage: 'Bank Account failed to save' }
					)
					if (response.data.status === 'Action') {
						this.$toastr.s(
							'Bank account update needs to be approved',
							'Needs Approval'
						)
					} else {
						this.$toastr.s('Bank account updated', 'Updated')
					}
					await this.loadBankAccount()
				}
				this.$v.$reset()
			} catch { }
		},
		async deleteBankAccount () {
			try {
				this.deleteCalledWithError = false
				if (this.linkedToGroups) {
					this.$snapbar.w('Cannot delete this bank account because it is linked to some groups.')
					this.deleteCalledWithError = true
					return
				}

				// Get confirmation from the user that they really want to delete the customer
				var swalResult = await swal.fire({
					title: 'Delete Bank Account',
					text: 'Are you sure you want to delete this bank account?',
					icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: colours.danger,
					confirmButtonText: 'Yes',
					cancelButtonText: 'No'
				})
			} catch (error) {
				// Do nothing as this will be if they clicked cancel
				return
			}

			if (!swalResult.isConfirmed) {
				return
			}

			try {
				const response = await axios.delete(
					`${process.env.VUE_APP_PLATFORM_API_URL}BankAccounts/${this.bankAccount.bankAccountId}`,
					{ showload: true, showerror: true, errormessage: 'Bank Account failed to delete' }
				)
				if (response.data.status === 'Action') {
					this.$toastr.s(
						'Bank account deletion needs to be approved',
						'Needs Approval'
					)
					await this.loadBankAccount()
				} else {
					this.$toastr.s('Bank Account deleted', 'Deleted')
					this.$router.push({ name: 'BankAccounts' })
				}
				this.$v.$reset()
			} catch { }
		},
		async loadBankAccount () {
			try {
				const response = await axios.get(
					`${process.env.VUE_APP_PLATFORM_API_URL}BankAccounts/${this.id}`,
					{
						params: { paygateId: this.$store.state.common.paygateId },
						showload: true,
						showerror: true,
						errormessage: 'Bank Account failed to load'
					}
				)
				this.bankAccount = response.data
			} catch (e) {
				this.$toastr.e(e.response.data, e.response.statusText)
			} finally {
				this.$nextTick(() => {
					this.$v.$reset()
				})
			}
		},
		async loadBankAccountGroups () {
			try {
				const response = await axios.get(
					`${process.env.VUE_APP_PLATFORM_API_URL}BankAccounts/GetBankAccountGroups/${this.id}`,
					{ params: { paygateId: this.$store.state.common.paygateId }, showload: true, showerror: true, errormessage: 'Failed to get bank account groups' }
				)
				const bankAccountGroups = response.data
				if ((bankAccountGroups !== undefined && bankAccountGroups.length > 0)) {
					this.linkedToGroups = true
					this.linkedGroups = bankAccountGroups
				}
			} catch (e) {
				this.$toastr.e(e.response.data, e.response.statusText)
			} finally {
				this.$nextTick(() => {
					this.$v.$reset()
				})
			}
		},
		digitsOrDashes: function () {
			if (!this.$v.bankAccount.sortCode.$invalid && !this.$v.bankAccount.sortCode.$invalid) {
				this.validate()
			} else {
				this.bankAccount.result = null
			}
		},
		validate: _.debounce(async function () {
			const data = { sortCode: this.bankAccount.sortCode, accountNumber: this.bankAccount.accountNumber }

			try {
				const response = await axios.post(`${process.env.VUE_APP_PLATFORM_API_URL}BankAccounts/ValidateBankAccount`, data, { showerror: true, errormessage: 'Failed to get validate bank account' })

				this.bankAccountValidation = response.data

				if (this.bankAccountValidation.valid) {
					this.bankAccount.bankName = this.bankAccountValidation.institutionName
					this.bankAccount.postcode = this.bankAccountValidation.postcode
					this.bankAccount.address1 = this.bankAccountValidation.address1
					this.bankAccount.address2 = this.bankAccountValidation.address2
					this.bankAccount.address3 = this.bankAccountValidation.address3
					this.bankAccount.address4 = this.bankAccountValidation.address4
				}
			} catch { }
		}, 800)
	},
	validations: {
		bankAccount: {
			reference: { required, maxLength: maxLength(50) },
			description: { maxLength: maxLength(200) },
			sortCode: {
				required,
				numeric,
				exactLength: exactLength(6)
			},
			accountNumber: {
				required,
				numeric,
				exactLength: exactLength(8)
			},
			accountType: {},
			currency: {},
			intermediateBankId: {}
		}
	}
}
</script>
