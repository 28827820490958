<template>
  <div id="sendSubmission">
    <div v-show="actionLoaded === true && !canDeleteAction">
      <div v-if="isLoggedIn === false" class="row">
        <div class="col-md-1">
          <button
            type="button"
            class="btn btn-primary"
            v-on:click="loginStart"
            :disabled="this.enableLogin === false"
          >Login</button>
        </div>
        <div class="col-md-1">
          <span style="font-size: 200%" class="fa fa-unlock"></span>
        </div>
        <div class="col-md-1"></div>
        <div class="col-md-2">
          <button
            type="button"
            class="btn btn-outline-secondary"
            v-on:click="viewVocalinkCertificate"
            :disabled="this.isLoggedIn === false"
          >{{ certificateButtonText }}</button>
        </div>
        <div class="col-md-7">
          <button
            v-show="enableReturnToViewImportedPayments"
            type="button"
            class="btn btn-outline-secondary ml-3"
            v-on:click="returnToViewImportedPayments"
            :disabled="this.enableLogin === false"
          >{{ returnToViewImportedPaymentsButtonText }}</button>
          <button
            type="button"
            class="btn btn-danger pull-right"
            v-on:click="cancelSubmission"
            :disabled="this.enableLogin === false && this.canCancel === false"
          >Cancel Submission</button>
        </div>
      </div>
      <div v-else>
        <div class="row">
          <div class="col-md-1">
            <button
              type="button"
              class="btn btn-outline-primary"
              v-on:click="logoff"
              :disabled="this.sendStarted === true"
            >Log Off</button>
          </div>
          <div class="col-md-3">
            <span style="font-size: 200%" class="fa fa-lock"></span>
            &nbsp;&nbsp;Securely logged into {{ networkName }}
          </div>
          <div class="col-md-1"></div>
          <div class="col-md-2">
            <button
              type="button"
              class="btn btn-outline-secondary"
              v-on:click="viewVocalinkCertificate"
              :disabled="this.sendStarted === true"
            >{{ certificateButtonText }}</button>
          </div>
          <div class="col-md-5">
            <button
              type="button"
              class="btn btn-danger pull-right"
              v-on:click="cancelSubmission"
              :disabled="this.sendStarted === true"
            >Cancel Submission</button>
          </div>
        </div>
        <br />
        <div class="row form-group">
          <div class="col-md-2">Submission Type</div>
          <div class="col-md-2">
            <b-select v-model.trim="selectedSubType" :options="submissionTypes" />
          </div>
        </div>
        <div class="row">
          <div class="col-md-2">
            <button
              type="button"
              class="btn btn-primary"
              v-on:click="sendSubmission"
              :disabled="this.sendStarted === true"
            >Send Submission</button>
          </div>
          <div v-show="this.sendStarted === true" class="col-md-10">
            <button
              type="button"
              class="btn btn-danger pull-right"
              v-on:click="cancelSendTask"
            >Abort Submission</button>
          </div>
        </div>
        <div v-show="sendStarted" class="row form-group"></div>
        <div v-show="sendStarted" class="row form-group">
          <div class="col-md-12">
            <b-progress striped :animated="true" :max="max" show-progress class="mb-3">
              <b-progress-bar :value="progress" :label="`${progress}%`"></b-progress-bar>
            </b-progress>
          </div>
        </div>
      </div>
    </div>
    <div v-show="canDeleteAction">
      <button
        type="button"
        class="btn btn-danger pull-right"
        v-on:click="deleteAction"
      >Delete Orphaned Action</button>
    </div>
    <div id="websignerContent" v-if="showWebSigner">
      <span v-html="webSignerHtml"></span>
    </div>    
    <div id="eSigner" v-show="showeSigner">
      <span v-html="eSignerHtml"></span>
    </div>
    <plugin @installCancelled="pluginNotInstalled"></plugin>
  </div>
</template>

<script>
import axios from 'axios'
import bacsCommon from '@/Lib/BacsCommon.js'
import common from '@/Assets/Components/UsbTokens/UsbTokenCommon'
import Plugin from '@/Assets/Components/UsbTokens/Plugin'
import moment from 'moment'
import groupTypes from '@/Assets/Constants/groupTypes'
import swal from 'sweetalert2'
import bacsMixin from '@/Lib/BacsMixin.js'
import supportLinkMixin from '@/Assets/Mixins/SupportLinkMixin'

export default {
  components: {
    plugin: Plugin
  },
  mixins: [
    bacsMixin,
    supportLinkMixin
  ],
  data () {
    return {
      submissionId: '',
      actionId: '',
      groupId: '',
      isLoaded: false,
      loggingIn: false,
      authResponse: '',
      isLoggedIn: false,
      loading: false,
      bacsGroup: {},
      base64Signature: '',
      submissionTypes: {},
      selectedSubType: 0,
      max: 100,
      progress: 0,
      getTaskProgressUrl: '',
      endTaskUrl: '',
      sendStarted: false,
      vocalinkCertificate: {},
      showPluginLink: false,
      downloadPluginUrl: '',
      eSignerHtml: '',
      showeSigner: false,
      networkType: '',
      canCancel: true,
      canProceed: true,
      canDeleteAction: false,
      newPluginRequired: false,
      pluginLoaded: false,
      paymentNetworkType: '',
      deviceType: '', // Either USB token or Smartcard depending on group.
      currentPage: '',
      componentId: 0, // Either 0 for USB access or 2 for smartcard component in version string returned by plugin.
      expectedVersion: '',
      userPin: '',
      browser: '',
      plugin: undefined,
      pluginUpdateNotInstalled: false,
      networkName: '',
      returnToViewImportedPaymentsButtonText: 'Return to Submission Details',
      webSignerHtml: '',
      showWebSigner: false,
      useWebSigner: false
    }
  },

  computed: {
    enableLogin: function () {
      return this.isLoaded === true && this.loggingIn === false && this.canProceed === true && this.pluginUpdateNotInstalled === false
    },

    actionLoaded () { return this.$store.getters.parentDataLoaded },

    certificateButtonText: function () {
      return this.networkName + ' Scheme Certificate'
    },
    enableReturnToViewImportedPayments () { return this.bacsGroup.groupType === 'BACS Bureau' }
  },

  methods: {
    async loginStart () {
      // First check that the action can proceed i.e. someone else hasn't got in there first and cancelled the submission or something!
      var json = JSON.stringify({
        submissionId: this.submissionId,
        actionId: this.actionId,
        nextStatus: 'Submitted'
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/canActionProceed',
        data: json
      })

      if (response.data.success) {
        if (this.bacsGroup.groupTypeDetails.tokenType === 'SmartCard') {
          await this.customerUsesWebSigner()
          if (!this.useWebSigner) {
            // Check for web extension and plugin...
            try {
              await this.$store.commit('setDeviceType', 'SmartCard')
              await this.$store.dispatch('checkPlugin')
              if (this.pluginUpdateNotInstalled === false) {
                await this.signChallenge()
              }
            } catch (error) {
              this.$toastr.w(error.message, 'Warning')
            }
          } else {
            await this.signChallenge()  
          }
        } else {
          await this.signChallenge()
        }
      } else {
        this.$snapbar.w(response.data.errorMessage)
        this.canProceed = false
        this.canCancel = false
        this.canDeleteAction = response.data.tag
      }
    },

    async signChallenge () {
      this.isLoggedIn = false
      this.loggingIn = true
      this.canProceed = false
      this.canCancel = false

      await this.getVocalinkLoginChallenge()

      if (this.authResponse.success === true) {
        // Sign either with HSM or smartcard depending on the submission group.
        this.bacsGroup = this.$store.getters.bacsGroup
        console.log('tokentype: ' + this.bacsGroup.groupTypeDetails.tokenType )

        if (this.bacsGroup.groupTypeDetails.tokenType === 'Hsm') {
          this.signChallengeWithHsm()
        } else {
            if (this.useWebSigner) {
              this.signWithWebSigner()
            } else {
              if (this.browser !== 'IE') {
                this.startSignNonActiveXPlugin()
              } else {
                this.signWithActiveXPlugin()
              }
            }
        }
      } else {
        this.canCancel = true
        this.canProceed = true
        this.isLoggedIn = false
        this.loggingIn = false
      }
    },

    async customerUsesWebSigner () {
      console.log('customerUsesWebSigner')
      let useWebSignerResponse = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}customers/useWebSigner`, { showload: true })
      this.useWebSigner = useWebSignerResponse.data
      console.log('useWebSigner: ' + this.useWebSigner)
    },

    async signWithWebSigner () {
      console.log('signWithWebSigner')
      var cookies = this.authResponse.cookieCollection
      var domain = ''
      var name = ''
      var value = ''
      if (cookies.length > 0) {
        var cookie = cookies[0]
        domain = cookie.domain
        name = cookie.name
        value = cookie.value
      }

      var url = `${process.env.VUE_APP_BACS_API_URL}bacs/comms/signChallengeWithWebSigner?submissionId=${this.submissionId}&actionId=${this.actionId}`
      url += '&challenge=' + this.authResponse.loginChallenge
      url += '&cookieName=' + name
      url += '&cookieValue=' + value
      url += '&cookieDomain=' + domain
      url += '&networkType=' + this.networkType
      url += '&groupId=' + this.groupId

      var response = await axios.get(url)
      this.webSignerHtml = response.data

      let challengeText = await this.extractLoginChallenge(this.webSignerHtml)

      var parseEvent = new CustomEvent('webSignerParse');
      document.dispatchEvent(parseEvent);

      const element = document.getElementById('websigner')
      if (element !== null) {
        element.remove()
      }

      const challengeDiv = `<div style="text-align: left;"><p>${challengeText}</p></div>`
      const websignerWarning = '<br/><div class="webSignerWarning">If you don\'t see a \'Sign\' button please install WebSigner. Then press F5 to refresh the page.</div><br/><br/>'

      let result = await swal.fire({
        title: 'Sign the following Login Challenge',
        html: websignerWarning + challengeDiv + this.webSignerHtml,
        icon: 'info',
        allowOutsideClick: false,
        showConfirmButton: false,
        showCancelButton: true,
        width: '650px'
      })

      console.log('result: ' + result.isConfirmed)
      if (!result.isConfirmed) {
        await this.unlockSubmission()
        this.loggingIn = false
        this.canProceed = true
      }
    },

    // Extract the Vocalink login challenge from the "GetLoginChallenge" response in order to display it in the sigining modal.
    // This is because Vocalink want the user to see the data that they are signing.
    async extractLoginChallenge(response) {
      let challengeText = '';
      let srchStr = 'data-in_data=\"';
      let idxStart = response.indexOf(srchStr);
      if (idxStart > 0) {
          challengeText = response.substring(idxStart + srchStr.length);
          let idxEnd = challengeText.indexOf('\"');
          if (idxEnd > 0) {
              challengeText = challengeText.substring(0, idxEnd);
          }
      }
      return challengeText;
    },

    async getWebSignerSignature () {
      console.log('getWebSignerSignature')
      let response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/comms/getWebSignerSignature?submissionId=` + this.submissionId, { showload: true })
      this.base64Signature = response.data
      await this.vocalinkLogin()
    },

    async signWithActiveXPlugin () {
      this.plugin = document.PayGatePlugin
      this.plugin.InitSmartCardSigning()
      this.plugin.SubmissionLength = this.authResponse.loginChallenge.length
      this.plugin.SubmissionData = this.authResponse.loginChallenge
      this.base64Signature = this.plugin.SmartCardSignature(true, 'gclib.dll', '')
      if (this.base64Signature.indexOf('Error') === -1) {
        await this.vocalinkLogin()
      } else {
        this.loggingIn = false
        this.$snapbar.e(this.base64Signature)
        await this.unlockSubmission()
        this.canCancel = true
        this.canProceed = true
      }
    },

    async startSignNonActiveXPlugin () {
      if (this.browser === 'Chrome') {
        this.processSignatureChrome()
          .then(() => {
          })
          .catch(() => {
            console.log('reject')
          })
      } else if (this.browser === 'Firefox') {
        this.processSignatureFirefox()
          .then(() => {
          })
          .catch(() => {
            console.log('reject')
          })
      }
    },

    processSignatureFirefox () {
      return new Promise((resolve, reject) => {
        document.addEventListener('getPluginResponseWebPage', (response) => {
          var pluginResponse = common.parsePluginResponse(JSON.parse(response.detail).response)
          this.base64Signature = pluginResponse.Base64Signature
          if (this.base64Signature !== null) {
            if (this.base64Signature.indexOf('Error') === -1) {
              this.vocalinkLogin()
              resolve()
            } else {
              this.showSupportLinkSnapbar(pluginResponse.Base64Signature)
              this.unlockSubmission()
              this.canCancel = true
              this.canProceed = true
              this.isLoggedIn = false
              this.loggingIn = false
              reject(new Error(pluginResponse.Base64Signature))
            }
          } else {
            this.$snapbar.e(pluginResponse.ErrorMessage)
            this.unlockSubmission()
            this.canCancel = true
            this.canProceed = true
            this.isLoggedIn = false
            this.loggingIn = false
            reject(new Error(pluginResponse.ErrorMessage))
          }
        }, { once: true })
        // eslint-disable-next-line
        var pluginEvent = new CustomEvent('clientCallPlugin', { 'detail': { PluginTask: 'SignWithSmartCard', DllName: 'gclib.dll', SigningAlgorithm: 'SHA256', IsDetached: 'true', Data: this.authResponse.loginChallenge } })
        document.dispatchEvent(pluginEvent)
      })
    },

    processSignatureChrome () {
      return new Promise((resolve, reject) => {
        // eslint-disable-next-line
        chrome.runtime.sendMessage(process.env.VUE_APP_CHROME_EXT_ID, { PluginTask: 'SignWithSmartCard', DllName: 'gclib.dll', SigningAlgorithm: 'SHA256', IsDetached: 'true', Data: this.authResponse.loginChallenge }, (response) => {
          var pluginResponse = common.parsePluginResponse(response.response)
          this.base64Signature = pluginResponse.Base64Signature
          if (this.base64Signature !== null) {
            if (this.base64Signature.indexOf('Error') === -1) {
              this.vocalinkLogin()
            } else {
              this.showSupportLinkSnapbar(pluginResponse.Base64Signature)
              this.unlockSubmission()
              this.canCancel = true
              this.canProceed = true
              this.isLoggedIn = false
              this.loggingIn = false
            }
          } else {
            this.$snapbar.e(pluginResponse.ErrorMessage)
            this.unlockSubmission()
            this.canCancel = true
            this.canProceed = true
            this.isLoggedIn = false
            this.loggingIn = false
          }
        })
        resolve()
      })
    },

    async getVocalinkLoginChallenge () {
      var bacsSubmission = this.$store.getters.bacsSubmission
      this.networkType = bacsSubmission.paymentNetworkType
      var url = `${process.env.VUE_APP_BACS_API_URL}bacs/comms/getVocalinkLoginChallenge?networkType=${this.networkType}&groupId=${this.groupId}&actionSubClass=Send`
      var response = await axios.get(url, { showload: true })
      this.authResponse = response.data
      if (this.authResponse.success === true) {
        this.vocalinkCertificate = this.authResponse.vocalinkCertificate
      } else {
        await this.unlockSubmission()
        if (!this.authResponse.licenceError) {
          this.$snapbar.e(this.authResponse.errorMessage)
        } else {
          this.$swal({
            title: 'Licence Warning',
            text: this.authResponse.errorMessage,
            type: 'warning'
          })
        }
      }
    },

    async callHsm () {
      var json = JSON.stringify({
        certificateThumbprint: this.bacsGroup.groupTypeDetails.certificate.thumbprint,
        loginChallenge: this.authResponse.loginChallenge,
        isPaygateOnline: false,
        userPin: this.userPin,
        submissionId: this.submissionId
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/comms/signChallengeWithHsmSendAction',
        data: json,
        showload: true
      })

      return response.data
    },

    async signChallengeWithHsm () {
      if (this.bacsGroup.groupTypeDetails.hsmPinRequired) {
        this.userPin = await bacsCommon.showVocalinkChallengePin(this.authResponse.loginChallenge)
      } else {
        await bacsCommon.showVocalinkChallengeNoPin(this.authResponse.loginChallenge)
      }

      var signResponse = await this.callHsm()

      if (signResponse.success === true) {
        this.base64Signature = signResponse.tag
        await this.vocalinkLogin()
      } else {
        this.$snapbar.e(signResponse.errorMessage)
        await this.unlockSubmission()
        this.loggingIn = false
        this.canProceed = true
      }
    },

    async signWitheSigner () {
      var cookies = this.authResponse.cookieCollection
      var domain = ''
      var name = ''
      var value = ''
      if (cookies.length > 0) {
        var cookie = cookies[0]
        domain = cookie.domain
        name = cookie.name
        value = cookie.value
      }

      var url = process.env.VUE_APP_BACS_API_URL + 'bacs/comms/signChallengeWitheSigner?submissionId=' + this.submissionId + '&actionId=' + this.actionId
      url += '&challenge=' + this.authResponse.loginChallenge
      url += '&cookieName=' + name
      url += '&cookieValue=' + value
      url += '&cookieDomain=' + domain
      url += '&networkType=' + this.networkType
      url += '&groupId=' + this.bacsGroup.groupId
      var response = await axios.get(url, { showload: true })
      this.eSignerHtml = response.data
      this.showeSigner = true
    },

    async vocalinkLogin () {
      var json = JSON.stringify({
        submissionId: this.submissionId,
        networkType: this.networkType,
        base64Signature: this.base64Signature,
        authResponse: this.authResponse
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/comms/vocalinkLogin',
        data: json,
        showload: true
      })

      this.loggingIn = false
      this.authResponse = response.data
      if (this.authResponse.success === true) {
        this.isLoggedIn = true
        this.$nextTick(() => {
          this.isLoggedIn = true
        })
      } else {
        this.showSupportLinkSnapbar(this.authResponse.errorMessage)
        await this.unlockSubmission()
        this.canCancel = true
        this.canProceed = true
        this.isLoggedIn = false
        this.loggingIn = false
      }
    },

    async logoff () {
      var json = JSON.stringify({
        networkType: this.networkType,
        authResponse: this.authResponse
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/comms/vocalinkLogoff',
        data: json,
        showload: true
      })

      this.authResponse = response.data
      this.isLoggedIn = false
      this.loggingIn = false
      this.isLoaded = true
      this.canCancel = true
      this.canProceed = true
      await this.unlockSubmission()
    },

    async startSendSubmission () {
      this.sendStarted = true

      // First check that the submission hasn't been sent in the meantime i.e. someone else hasn't got in there first!
      var json = JSON.stringify({
        submissionId: this.submissionId,
        actionId: this.actionId,
        nextStatus: 'Submitted'
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/canActionProceed',
        data: json
      })

      if (response.data.success) {
        this.sendSubmission()
      } else {
        this.$snapbar.w(response.data.errorMessage)
        this.canProceed = false
        this.canCancel = false
        await this.logoff()
      }
    },

    sendSubmission () {
      this.sendStarted = true
      this.progress = 0

      this.bacsGroup = this.$store.getters.bacsGroup

      var isMultiFile = false
      if (this.bacsGroup.groupType === groupTypes.bureau) {
        isMultiFile = true
      } else if (this.bacsGroup.groupType === groupTypes.collections && this.bacsGroup.groupTypeDetails.submissionType === 1) {
        // Collections bureau group.
        isMultiFile = true
      }

      var json = JSON.stringify({
        submissionId: this.submissionId,
        actionId: this.actionId,
        authResponse: this.authResponse,
        fileNumber: 0,
        fromRecordNumber: 0,
        toRecordNumber: 0,
        selectedSubType: this.selectedSubType,
        deletePaymentData: this.bacsGroup.groupTypeDetails.deletePaymentData,
        isMultiFile: isMultiFile
      })

      axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/comms/submitToVocalink/',
        data: json
      })

      let canPush = true
      var id = window.setInterval(() => {
        return axios.get(this.getTaskProgressUrl, {
        })
          .then(response => {
            this.progress = response.data
            if (this.progress >= 100) {
              window.clearInterval(id)
              return axios.get(this.endTaskUrl, {
                showload: true
              })
                .then(response => {
                  if (response.data === '') {
                    if (this.bacsGroup.groupType === groupTypes.collections && this.selectedSubType === 'structuralTest') {
                      this.$store.dispatch('updateCurrentCollectionsSubmissionCount')
                    }
                    this.$store.dispatch('updateBureauSubmissionPill')
                    // Depending on timings, another call is made to EndSendTask where response.data is '' causing the router to push even if there is an error.
                    // Only push to next page if there was no error i.e. earlier call has response.data = to some error message.
                    if (canPush) {
                      window.setTimeout(() => {
                        this.$router.push('/payments/common/viewSubmissionSummary')
                      }, 1000)
                    }
                  } else {
                    canPush = false
                    this.isLoggedIn = false
                    this.sendStarted = false
                    this.canProceed = true
                    this.canCancel = true
                    this.progress = 0
                    this.showSupportLinkSnapbar(response.data)
                  }
                })
            } else if (this.progress === -1) {
              window.clearInterval(id)
              this.sendStarted = false
              this.isLoggedIn = false
            }
          })
          .catch(error => console.log(error))
      }, 1000)
    },

    cancelSendTask () {
      var url = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/cancelTask?submissionId=' + this.submissionId
      return axios.get(url, {
        showload: true
      })
        .then(response => {
          var id = window.setInterval(() => {
            return axios.get(this.getTaskProgressUrl, {
            })
              .then(response => {
                this.progress = response.data
                if (this.progress >= 100) {
                  window.clearInterval(id)
                  return axios.get(this.endTaskUrl, {
                    showload: true
                  })
                    .then(response => {
                      if (response.data === '') {
                        window.setTimeout(() => {
                          this.$router.push('/payments/common/viewSubmissionSummary')
                        }, 1000)
                      } else {
                        this.$snapbar.w(response.data)
                        this.canProceed = true
                      }
                    })
                }
              })
              .catch(error => console.log(error))
          }, 1000)
        })
    },

    async getVocalinkCertificate () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/comms/getVocalinkCertificate?networkType=` + this.networkType, { showload: true })
      if (response.data.success === true) {
        this.vocalinkCertificate = response.data.vocalinkCertificate
      } else {
        this.$snapbar.e(response.data.errorMessage)
      }

      return response.data.success
    },

    async viewVocalinkCertificate () {
      if (this.vocalinkCertificate.issuer === undefined) {
        var success = await this.getVocalinkCertificate()
        if (success === true) {
          await swal.fire({
            title: this.certificateButtonText,
            html: bacsCommon.vocalinkCertificateDetails(this.vocalinkCertificate),
            icon: 'info',
            allowOutsideClick: false
          })
        }
      } else {
        await swal.fire({
          title: this.certificateButtonText,
          html: bacsCommon.vocalinkCertificateDetails(this.vocalinkCertificate),
          icon: 'info',
          allowOutsideClick: false
        })
      }
    },

    async getSubmissionTypes () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/comms/getSubmissionTypes?submissionId=` + this.submissionId)
      this.submissionTypes = response.data
      this.selectedSubType = this.submissionTypes[0].value
    },

    async geteSignerSignature () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/comms/geteSignerSignature?submissionId=${this.submissionId}&isSubmissionLogin=true`)
      this.base64Signature = response.data
      await this.vocalinkLogin()
    },

    async cancelSubmission () {
      // First check that the submission hasn't been sent in the meantime i.e. someone else hasn't got in there first!
      var json = JSON.stringify({
        submissionId: this.submissionId,
        actionId: this.actionId,
        nextStatus: 'Submitted'
      })

      var response = await axios({
        method: 'POST',
        url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/canActionProceed',
        data: json
      })

      if (response.data.success) {
        this.isLoaded = false
        var cancelReponse = await bacsCommon.cancelSubmissionDialog()
        if (cancelReponse.cancel === true) {
          this.bacsGroup = this.$store.getters.bacsGroup

          json = JSON.stringify({
            submissionId: this.submissionId,
            actionId: this.actionId,
            serviceUserNumber: this.bacsGroup.groupTypeDetails.serviceUserNumber,
            fileNumber: 0,
            fromRecordNumber: 0,
            toRecordNumber: 0,
            deletePaymentData: this.bacsGroup.groupTypeDetails.deletePaymentData,
            cancellationReason: cancelReponse.cancellationReason
          })

          await bacsCommon.confirmCancellation(json, axios, this.$toastr, this.$router)
          await this.$store.dispatch('cancelCollectionsSubmission', this.submissionId)
          await this.$store.dispatch('updateBureauSubmissionPill')
        } else {
          var endTaskUrl = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/EndTask?submissionId=' + this.submissionId
          await axios.get(endTaskUrl)
          this.isLoaded = true
        }
      } else {
        this.$snapbar.w(response.data.errorMessage)
        this.canProceed = false
        this.canCancel = false
        this.canDeleteAction = response.data.tag
      }
    },

    async getBacsSubmission () {
      var bacsSubmission = this.$store.getters.bacsSubmission
      this.paymentNetworkType = bacsSubmission.paymentNetworkType
      this.networkName = this.paymentNetworkType === 0 ? 'BACS' : 'Faster Payments'
      this.groupId = bacsSubmission.groupId
    },

    async getBacsGroup () {
      await this.$store.dispatch('getSubBacsGroup', this.groupId)
      this.bacsGroup = this.$store.getters.bacsGroup
    },

    async isServiceWindowOpen () {
      var response = await axios.get(`${process.env.VUE_APP_BACS_API_URL}bacs/processingDate/isServiceWindowOpen?paymentNetworkType=` + this.paymentNetworkType)

      if (response.data.isWindowOpen === false) {
        this.canProceed = false
        this.$snapbar.w('You cannot send your submission at this time as the ' + this.networkName + ' service window is closed.')
      }
    },

    pluginNotInstalled () {
      this.pluginUpdateNotInstalled = true
    },

    async waitForParent () {
      var looping = true
      while (looping) {
        if (this.actionLoaded === false) {
          await new Promise(resolve => setTimeout(resolve, 500))
        } else {
          looping = false
        }
      }
    },

    async checkFpsSubmission () {
      if (this.paymentNetworkType === 1) {
        var today = new Date()
        var todayDateFormatted = moment(today).format('YYYY-MM-DD')
        var outOfDateSubmission = false
        var intelligibleSummaries = this.$store.getters.intelligibleSummaries
        intelligibleSummaries.forEach(element => {
          var procDate = moment(element.processingDate).format('YYYY-MM-DD')

          if (todayDateFormatted > procDate) {
            outOfDateSubmission = true
          }
        })

        if (outOfDateSubmission) {
          this.canProceed = false
          this.$snapbar.e('This submission cannot be sent to Vocalink because the processing date for this Faster Payment submission has passed.')
        }
      }
    },

    async unlockSubmission () {
      var unlockResponse = await axios.get(process.env.VUE_APP_BACS_API_URL + 'bacs/submission/RemoveUserSubmissionLock?submissionId=' + this.submissionId)
      if (unlockResponse.data.status === 'Failed') {
        this.$snapbar.e(unlockResponse.data.errorText)
      }
    },

    async deleteAction () {
      var url = process.env.VUE_APP_PLATFORM_API_URL + 'actions/deleteRejected/' + this.actionId
      await axios.delete(url)
      this.$router.push('/actions')
    }
  },

  async mounted () {
    // Init websigner div so it exists when needed.
    this.webSignerHtml = '<div id="websigner" data-mode="button" data-in_data="DUMMY" data-reply_url="DUMMY" data-certfilter_ku=""192:8""></div>'
    this.showWebSigner = true;

    await this.waitForParent()
    this.submissionId = this.$store.getters.submissionId
    this.actionId = this.$store.getters.actionId
    this.currentPage = process.env.VUE_APP_BACS_API_URL + '/bacsActions/actionWrapper?actionId=' + this.actionId + '&actionedObjectId=' + this.submissionId + '&actionSubClass=Send'
    this.getTaskProgressUrl = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/GetTaskProgress?submissionId=' + this.submissionId
    this.endTaskUrl = process.env.VUE_APP_BACS_API_URL + 'bacs/comms/EndSendTask?submissionId=' + this.submissionId

    this.browser = bacsCommon.getBrowser()
    await this.getSubmissionTypes()
    await this.getBacsSubmission()
    await this.getBacsGroup()
    await this.isServiceWindowOpen()
    await this.checkFpsSubmission()

    this.numberOfSubmissionBytes = 1 // Not signing a submission but want to use eSigner if no plugin loaded when using IE.

    // Check if redirected here after signing Vocalink challenge with eSigner.
    var getSignature = this.$route.query.getSignature
    var extLoaded = this.$route.query.pluginAccessible

    if (getSignature === 'true') {
      this.authResponse = {}

      var cookie = {}
      // eslint-disable-next-line
      cookie['name'] = this.$route.query.cookieName
      // eslint-disable-next-line
      cookie['value'] = this.$route.query.cookieValue
      // eslint-disable-next-line
      cookie['domain'] = this.$route.query.cookieDomain

      var cookieCollection = []
      cookieCollection.push(cookie)

      this.authResponse.cookieCollection = cookieCollection
      this.authResponse.cookieValueIsBase64 = true
      this.authResponse.loginChallenge = this.$route.query.challenge
      this.networkType = this.$route.query.networkType

      await this.getWebSignerSignature()
    } else if (extLoaded === 'true') {
      this.pluginCheckComplete = true
      this.isLoggedIn = false
      this.isLoaded = true
      this.loginStart()
    }
    this.isLoaded = true
  }
}
</script>
<style>
  .webSignerWarning {
    color: blue;
    text-align: left; 
  }
  
  .dark-mode {
    .webSignerWarning {
      color: whitesmoke;
      text-align: left; 
    }
  }
</style>