var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("loading", { attrs: { loading: _vm.loading } }),
      _c("div", { staticClass: "idb-block" }, [
        _c("div", { staticClass: "idb-block-title" }, [
          _c(
            "h2",
            [
              _vm._v(" Create Bureau Job "),
              _c("help-icon", { attrs: { docPath: "/bacsbureau/jobs/" } }),
              _c("favourite-icon")
            ],
            1
          )
        ]),
        _c(
          "div",
          { staticClass: "idb-block-content" },
          [
            _c(
              "b-row",
              [
                _c("b-col", { attrs: { sm: "2" } }, [
                  _c(
                    "label",
                    { staticClass: "mt-2 required", attrs: { for: "filter" } },
                    [_vm._v("Group")]
                  )
                ]),
                _c(
                  "b-col",
                  { attrs: { sm: "3" } },
                  [
                    _c("group-select", {
                      attrs: {
                        id: "filter",
                        groups: _vm.groups,
                        clearable: false
                      },
                      on: { input: _vm.SilentSave },
                      model: {
                        value: _vm.bureauJob.groupId,
                        callback: function($$v) {
                          _vm.$set(_vm.bureauJob, "groupId", $$v)
                        },
                        expression: "bureauJob.groupId"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "mt-2" },
              [
                _c(
                  "label",
                  {
                    staticClass: "label-control col-md-2 required",
                    attrs: { for: "bureau-job-name" }
                  },
                  [_vm._v("Bureau Job Name")]
                ),
                _c(
                  "b-col",
                  { attrs: { sm: "3" } },
                  [
                    _c("b-form-input", {
                      attrs: {
                        id: "bureau-job-name",
                        type: "text",
                        formatter: _vm.trimInput
                      },
                      on: { input: _vm.checkName },
                      model: {
                        value: _vm.bureauJob.name,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.bureauJob,
                            "name",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "bureauJob.name"
                      }
                    }),
                    _vm.nameInUse
                      ? _c("small", { staticClass: "text-danger" }, [
                          _vm._v("Bureau Job Name already exists")
                        ])
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "mt-2" },
              [
                _c("b-col", { attrs: { sm: "2" } }, [
                  _c("label", { attrs: { for: "deleteSourceFilesCheckbox" } }, [
                    _vm._v("Delete Source Files")
                  ])
                ]),
                _c(
                  "b-col",
                  { attrs: { sm: "3" } },
                  [
                    _c("p-check", {
                      staticClass: "p-switch p-fill",
                      attrs: {
                        labelId: "deleteSourceFilesCheckbox",
                        color: "primary",
                        id: "deleteSourceFilesCheckbox"
                      },
                      on: { change: _vm.SilentSave },
                      model: {
                        value: _vm.bureauJob.deleteSourceFiles,
                        callback: function($$v) {
                          _vm.$set(_vm.bureauJob, "deleteSourceFiles", $$v)
                        },
                        expression: "bureauJob.deleteSourceFiles"
                      }
                    }),
                    _c("span", { staticClass: "form-text small" }, [
                      _vm._v(
                        "Specifies whether the source files will be deleted upon successfully being submitted to BACS"
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "mt-3" },
              [
                _c(
                  "b-col",
                  { attrs: { sm: "12" } },
                  [
                    _c(
                      "vue-good-table",
                      {
                        ref: "table",
                        attrs: {
                          paginationOptions: _vm.paginationOptions,
                          rows: _vm.rows,
                          columns: _vm.columns,
                          lineNumbers: true,
                          styleClass: "vgt-table striped bordered",
                          searchOptions: {
                            enabled: true
                          },
                          "sort-options": _vm.sortOptions,
                          mode: "remote",
                          totalRows: _vm.totalRecords,
                          isLoading: _vm.isTableLoading
                        },
                        on: {
                          "on-cell-click": _vm.onCellClick,
                          "on-row-click": function() {},
                          "on-page-change": _vm.onPageChange,
                          "on-per-page-change": _vm.onPerPageChange,
                          "on-search": _vm.onSearch,
                          "on-sort-change": _vm.onSortChange,
                          "update:isLoading": function($event) {
                            _vm.isTableLoading = $event
                          },
                          "update:is-loading": function($event) {
                            _vm.isTableLoading = $event
                          }
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "table-row",
                            fn: function(props) {
                              return [
                                props.column.field == "actions"
                                  ? _c("span", [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-sm btn-warning",
                                          attrs: {
                                            id: "onDuplicateJobDetailClick",
                                            title: "Duplicate Job Detail",
                                            disabled: _vm.isLoading
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.onDuplicateJobDetailClick(
                                                props.row
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "fa fa-copy"
                                          })
                                        ]
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-sm btn-danger ml-1",
                                          attrs: {
                                            id: "onDeleteJobDetailClick",
                                            title: "Delete Job Detail",
                                            disabled: _vm.isLoading
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.onDeleteJobDetailClick(
                                                props.row
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("span", {
                                            staticClass: "fa fa-trash-alt"
                                          })
                                        ]
                                      )
                                    ])
                                  : props.column.field === "hasWildcard"
                                  ? _c("span", [
                                      props.row.hasWildcard
                                        ? _c("i", {
                                            staticClass: "fa fa-check"
                                          })
                                        : _c("i", {
                                            staticClass: "fa fa-times"
                                          })
                                    ])
                                  : props.column.field === "customerName"
                                  ? _c("span", [
                                      props.row.customerName === " ()"
                                        ? _c("span", [
                                            _c("i", [_vm._v("Not Set")])
                                          ])
                                        : _c("span", [
                                            _vm._v(
                                              _vm._s(props.row.customerName)
                                            )
                                          ])
                                    ])
                                  : props.column.field === "importSchema"
                                  ? _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            props.row.mapping === null
                                              ? props.formattedRow[
                                                  props.column.field
                                                ]
                                              : _vm.getMappingName(
                                                  props.row.mapping
                                                )
                                          ) +
                                          " "
                                      )
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            props.formattedRow[
                                              props.column.field
                                            ]
                                          ) +
                                          " "
                                      )
                                    ])
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _c(
                          "div",
                          {
                            attrs: { slot: "table-actions" },
                            slot: "table-actions"
                          },
                          [
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value: "Clear filters",
                                    expression: "'Clear filters'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                staticClass: "btn btn-link",
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.clearTableFilters($event)
                                  }
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "fa-stack",
                                    staticStyle: { "font-size": "10px" }
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fa fa-filter fa-stack-1x dimmedIcon"
                                    }),
                                    _c("i", {
                                      staticClass:
                                        "fa fa-ban fa-stack-2x dimmedIcon"
                                    })
                                  ]
                                ),
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v("Clear filters")
                                ])
                              ]
                            ),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value: "Refresh the data in the table",
                                    expression:
                                      "'Refresh the data in the table'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                attrs: {
                                  variant: "link",
                                  id: "refreshJobDetailTableButton"
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.load($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-sync pointer dimmedIcon"
                                }),
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v("Refresh Table")
                                ])
                              ]
                            ),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value: "Export the contents of the table",
                                    expression:
                                      "'Export the contents of the table'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                attrs: {
                                  variant: "link",
                                  id: "export-table-button"
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.exportTable($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "fa fa-file-export pointer dimmedIcon"
                                }),
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v("Export Table")
                                ])
                              ]
                            ),
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-popover",
                                    rawName: "v-b-popover.hover.top.d500",
                                    value: "Add Job Detail",
                                    expression: "'Add Job Detail'",
                                    modifiers: {
                                      hover: true,
                                      top: true,
                                      d500: true
                                    }
                                  }
                                ],
                                attrs: {
                                  id: "addNewJobDetailButton",
                                  variant: "link"
                                },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.onAddNewJobDetailClick($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-plus pointer dimmedIcon"
                                }),
                                _c("span", { staticClass: "sr-only" }, [
                                  _vm._v("Add new Job Detail")
                                ])
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            attrs: { slot: "table-actions-bottom" },
                            slot: "table-actions-bottom"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "row",
                                staticStyle: { padding: "8px 0 8px 8px" }
                              },
                              [
                                _c("div", { staticClass: "col-md-2" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-outline-primary",
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.onAddNewJobDetailClick(
                                            $event
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("Add Job Detail")]
                                  )
                                ])
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "idb-block-footer" },
          [
            _c(
              "b-button",
              {
                attrs: {
                  variant: "primary",
                  id: "saveJobButton",
                  disabled:
                    _vm.isLoading ||
                    _vm.bureauJob.groupId === undefined ||
                      _vm.bureauJob.groupId === null ||
                    _vm.bureauJob.name === undefined ||
                      _vm.bureauJob.name === null ||
                      _vm.bureauJob.name === "" ||
                    _vm.nameInUse
                },
                on: {
                  click: function($event) {
                    return _vm.onSave()
                  }
                }
              },
              [_vm._v("Save")]
            ),
            _c(
              "b-button",
              {
                staticClass: "float-right",
                attrs: {
                  id: "clearJobButton",
                  variant: "danger",
                  disabled: _vm.isLoading
                },
                on: {
                  click: function($event) {
                    return _vm.onResetCreateJobClick()
                  }
                }
              },
              [_vm._v("Clear Job")]
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        [
          _c("addBureauJobDetailModal", {
            attrs: {
              bureauJobId: _vm.bureauJob.bureauJobId,
              bureauCustomers: _vm.bureauCustomers
            },
            on: { submit: _vm.addBureauJobDetailClosed }
          })
        ],
        1
      ),
      _c(
        "div",
        [
          _c("editBureauJobDetailModal", {
            attrs: {
              bureauJobId: _vm.bureauJob.bureauJobId,
              bureauJobDetailId: _vm.bureauJobDetailId,
              bureauCustomers: _vm.bureauCustomers,
              duplicateMode: _vm.duplicateMode
            },
            on: { submit: _vm.editBureauJobDetailClosed }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }