<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Bureau Customer Verification
          <help-icon></help-icon>
          <favourite-icon></favourite-icon>
        </h2>
      </div>

      <div class="idb-block-content">
        <b-card
          v-if="TotalRecordsNeedVerification > 0"
          bg-variant="warning"
          style="margin-bottom: 0.8em;"
        >Cannot perform complete import as records require verification</b-card>
        <div class="row">
          <div class="col-4">
            <table class="table table-striped payment-details-summary pt-0 mt-0 mb-2">
              <tbody>
                <tr><td><strong>Filename</strong></td><td class="text-right">{{ Filename }}</td></tr>
                <tr><td><strong>Valid Customers</strong></td><td class="text-right">{{ TotalRecordsVerified }}</td></tr>
                <tr><td><strong>Invalid Customers</strong></td><td class="text-right">{{ TotalRecordsNeedVerification }}</td></tr>
                <tr><td><strong>Deleted</strong></td><td class="text-right">{{ TotalRecordsDeleted }}</td></tr>
                <tr><td><strong>Total Customers</strong></td><td class="text-right">{{ totalRecordsInImport }}</td></tr>
              </tbody>
            </table>
          </div>
        </div>
        <vue-good-table class="table-unset"
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :rows="rows"
          :columns="columns"
          :searchOptions="{ enabled: true }"
          mode="remote"
          :totalRows="totalRecords"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          @on-sort-change="onSortChange"
          @on-cell-click="goToEdit"
          @on-row-click="rowClickEvent"
          ref="table"
          :isLoading.sync="isTableLoading"
          styleClass="vgt-table striped bordered"
        >
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span><span class="sr-only">Clear filters</span>
            </button>
            <b-button
              @click.prevent="load"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Refresh the data in the table'"
            >
              <i class="fa fa-sync pointer dimmedIcon"></i><span class="sr-only">Refresh the data in the table</span>
            </b-button>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.label === 'Status'">
              <span
                v-if="props.row.deleted"
                class="w-100 badge badge-pill badge-warning"
                v-b-popover.hover.top.d500="'Deleted'"
              >Deleted</span>
              <span
                v-else-if="props.row.errorlevel > 0"
                class="w-100 badge badge-pill badge-danger"
                v-b-popover.hover.top.d500="'This Customer is invalid and cannot be imported'"
              >Invalid</span>
              <span
                v-else
                class="w-100 badge badge-pill badge-success"
                v-b-popover.hover.top.d500="'This Customer is valid and may be imported'"
              >Valid</span>
            </span>
            <span v-else-if="props.column.field === 'name'">
              <span v-if="props.row.deleted" class="pl-2">
                {{ props.row.name }}
              </span>
              <span v-else>{{ props.row.name }}</span>
            </span>
            <span v-else-if="props.column.field === 'serviceUserNumber'">
              <span v-if="props.row.deleted" class="pl-2">
                {{ props.row.serviceUserNumber }}
              </span>
              <span v-else>{{ props.row.serviceUserNumber }}</span>
            </span>
            <span v-else-if="props.column.field === 'errorMessages'">
              <span v-if="props.row.bureauImportStatus == 'Editing'">
                <span v-if="props.row.deleted === true">
                  Removed from import
                </span>
                <ul class="list-unstyled"
                  v-else-if="
                    props.row.errorlevel > 0 && props.row.deleted === false
                  "
                >
                  <li v-if="props.row.errorMessages !== null && props.row.errorMessages.length > 0" v-for="error in props.row.errorMessages" :key="error">
                    {{ error }}
                  </li>
                </ul>
                <span v-else>This Customer is valid and may be imported</span>
              </span>
              <span v-else style="color: red">{{ props.row.bureauImportStatus }}</span>
            </span>
            <span v-else-if="props.column.field === 'actions'">
              <b-dropdown
                variant="link"
                size="lg"
                no-caret
                style="margin-top:-10px;margin-right:-30px;"
              >
                    <template slot="button-content">
                      <i class="fa fa-ellipsis-h" style="color:#878787"></i>
                      <span class="sr-only">Actions</span>
                    </template>
                    <b-dropdown-item v-if="!props.row.deleted" :disabled="props.row.bureauImportStatus !== 'Editing' && props.row.bureauImportStatus !== 'Failed'"
                      @click.stop="onRecordClick(props.row)"
                    >Edit Customer</b-dropdown-item>
                    <b-dropdown-item :disabled="props.row.bureauImportStatus !== 'Editing'" v-if="props.row.deleted"
                     @click.stop="onRecordRestore(props.row)"
                    >Restore Customer</b-dropdown-item>
                    <b-dropdown-item :disabled="props.row.bureauImportStatus !== 'Editing'" v-if="!props.row.deleted"
                      @click.stop="onRecordDelete(props.row)">Delete Customer</b-dropdown-item>
                    <b-dropdown-item v-if="!props.row.deleted" :disabled="(props.row.bureauImportStatus !== 'Editing' && props.row.bureauImportStatus !== 'Failed') || props.row.errorlevel > 0"
                      @click.stop="onImportSingle(props.row)">Import single Customer</b-dropdown-item>
                  </b-dropdown>
            </span>
            <span v-else-if="props.column.field === 'checkbox'">
              <label :for="'addToArray_'+props.row.bureauCustomerId" class="sr-only">Add To Array</label>
              <p-check :labelId="'addToArray_'+props.row.bureauCustomerId"
                class="p-default p-curve"
                @change="addToBCArray(props.row)"
                :disabled="
                  props.row.deleted === true ||
                  (props.row.bureauImportStatus !== 'Editing' && props.row.bureauImportStatus !== 'Failed') || props.row.errorlevel > 0
                "
                       :checked="BureauCustomerImportIds.some(x=> x===props.row.bureauCustomerId)"
              ></p-check>
            </span>
          </template>
        </vue-good-table>
      </div>

      <div class="idb-block-footer">
        <span v-if="this.checkboxesSelected === true">
          <button class="btn btn-primary" @click="onVerifySelectedRecords()">Verify Selected</button>
          <button class="btn btn-primary ml-2" @click="onImportSelectedRecords()">Import Selected</button>
        </span>
        <span v-else>
          <button class="btn btn-primary" @click="onVerifyRecords()">Verify Records</button>
          <button class="btn btn-primary ml-2" :disabled="TotalRecordsNeedVerification > 0" @click="onImportClick()">Complete Import</button>
        </span>
        <span><button class="btn btn-danger float-right" @click="onDeleteClick">Delete Import Records</button></span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert2'
import _ from 'lodash'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import auth from '@/Assets/Components/Authentication/auth.js'
import Utility from '@/Assets/Mixins/Utility'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import colours from '@/Assets/Constants/colours'

export default {
  mixins: [
    Utility,
    tableFilterMixin
  ],
  data () {
    return {
      BureauImportStatus: {},
      bureauCustomerData: {},
      importSessionId: '',
      BureauCustomerImportIds: [],
      Filename: '',
      totalRecordsInImport: 0,
      totalRecords: 0,
      totalPages: 0,
      TotalRecordsVerified: 0,
      TotalRecordsNeedVerification: 0,
      TotalRecordsDeleted: 0,
      columns: [
        {
          label: '',
          field: 'checkbox',
          sortable: false,
          tdClass:'text-center'
        },
        {
          label: 'Name',
          field: 'name'
        },
        {
          label: 'Service User Number',
          field: 'serviceUserNumber'
        },
        {
          label: 'Status',
          field: 'status',
          sortable: false,
          tdClass:'text-center',
          thClass:'text-center'
        },
        {
          label: 'Messages',
          field: 'errorMessages',
          sortable: false
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
          tdClass:'text-center',
          thClass:'text-center'
        }
      ],
      rows: [],
      serverParams: {
        sort: [{ field: 'errorLevel', type: 'desc' }]
      },
      checkboxesSelected: false,

      // signalR stuff
      writebackStatusHubUrl: '',
      writebackStatusConnection: null
    }
  },
  watch: {
    'serverParams.perPage': function (val, oldVal) {
      this.load()
    }
  },
  async mounted () {
    auth.getAccessToken()
      .then(token => {
        this.bearerToken = 'Bearer ' + token
        this.writebackStatusConnection = new HubConnectionBuilder().withUrl(this.writebackStatusHubUrl, { accessTokenFactory: async () => token }).configureLogging(LogLevel.Error).build()
        this.writebackStatusConnection.start()
        this.writebackStatusConnection.on('RefreshWritebackStatus', data => {

          this.updateBureauCustomerStatus(data)
        })
        // this.writebackStatusConnection.on('UploadComplete', data => {
        //   this.$toastr.s(data.originalFileName + '. successfully uploaded')
        // })
      })
  },
  created () {
    this.BureauImportStatus = this.loadBureauImportStatus()
    this.writebackStatusHubUrl = process.env.VUE_APP_BUREAU_API_URL + 'hubs/writebackstatushub'

    if (this.$route.params.id === '00000000-0000-0000-0000-000000000000') {
      // push it back towards the imported customer list
    } else {
      console.log(this.$route.params.id)
      this.importSessionId = this.$route.params.id
      this.getFileDetails()
      this.load()
    }
  },

  methods: {
    updateBureauCustomerStatus (data) {
      console.log('FROM IMPORT VERIFY HUB',data)
      if (data.importSessionId !== undefined && data.importSessionId !== null && data.importSessionId === this.importSessionId) {

        if (data.bureauFileStatus === 5) {
          this.$router.push({ name: 'ImportBureauCustomers' })
        }
      } else {
        for (var j = 0; j < this.bureauCustomerData.length; j++) {
          if (data.bureauCustomerId !== undefined && data.bureauCustomerId !== null && this.bureauCustomerData[j].bureauCustomerId === data.bureauCustomerId) {
            this.bureauCustomerData[j].bureauImportStatus = data.recordStatus
          }
        }
      }
    },
    rowClickEvent (e) {

    },
    goToEdit (cell) {
      if (cell.column.field !== 'actions' && cell.column.field !== 'checkbox') {
        this.$router.push({ name: 'ImportBureauCustomerEdit', params: { id: cell.row.bureauCustomerId } })
      }
    },

    onRecordClick (item) {
      this.$router.push({ name: 'ImportBureauCustomerEdit', params: { id: item.bureauCustomerId } })
    },
    addToBCArray (item) {
      if (!_.includes(this.BureauCustomerImportIds, item.bureauCustomerId)) {
        this.BureauCustomerImportIds.push(item.bureauCustomerId)
      } else {
        var idString = item.bureauCustomerId + ''
        var i = 0
        var Arr = []
        for (i = 0; i < this.BureauCustomerImportIds.length; i++) {
          if (this.BureauCustomerImportIds[i] !== idString) {
            Arr.push(this.BureauCustomerImportIds[i])
          }
        }
        this.BureauCustomerImportIds = Arr
      }
      console.log(this.BureauCustomerImportIds)
      this.checkboxesSelected = !(this.BureauCustomerImportIds.length === 0)
    },
    onRecordDelete: async function (item) {
      var result = await swal.fire({
        title: 'Remove this Bureau Customer',
        text: 'Are you sure you want to remove this bureau customer from the import?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (result) {
        await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer/removeBureauCustomerFromImport',
          { importSessionId: this.importSessionId, bureauCustomerId: item.bureauCustomerId }, { showload: true })
          .then(response => {
            if (response.data.toastType === 2) {
              this.$toastr.s(response.data.toastMessage)
            } else {
              this.$toastr.e(response.data.toastMessage)
            }
            this.load()
          })
      }
      await this.getFileDetails()
    },
    onRecordRestore: async function (item) {
      var result = await swal.fire({
        title: 'Restore Bureau Customer into Import',
        text: 'Are you sure you want to restore this bureau customer back into the import?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      if (result) {
        await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer/restoreBureauCustomerToImport',
          { importSessionId: this.importSessionId, bureauCustomerId: item.bureauCustomerId }, { showload: true })
          .then(response => {
            if (response.data.toastType === 2) {
              this.$toastr.s(response.data.toastMessage)
            } else {
              this.$toastr.e(response.data.toastMessage)
            }
            this.load()
          })
      }
      await this.getFileDetails()
    },

    onUpdateClick: async function (item) {
      var params = {
        params: {
          importSessionId: this.ImportSessionId,
          bureauCustomerId: this.BureauCustomerId,
          name: item.name,
          serviceusernumber: item.serviceusernumber
        }
      }
      await axios.put(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer/quickUpdateBureauCustomer', params, { showload: true })
        .then(response => {
          if (response.data.toastType === 2) {
            this.$toastr.s(response.data.toastMessage)
          } else {
            this.$toastr.e(response.data.toastMessage)
          }
          this.$router.push({ name: 'Import', params: { id: this.ImportSessionId } })
        })
    },
    getFileDetails: async function () {
      axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer/GetBureauCustomerImportFileDetails/' + this.importSessionId, { showload: true })
        .then(response => {
          this.Filename = response.data.filename
          this.totalRecordsInImport = response.data.totalRecords
          this.TotalRecordsVerified = response.data.recordsVerified
          this.TotalRecordsNeedVerification = response.data.recordsRequireVerification
          this.TotalRecordsDeleted = response.data.recordsDeleted
        })
        .catch(error => {
          this.$toastr.e(error)
        })
    },
    onVerifySingle: async function (item) {
      var bureauCustomerIdToVerify = []
      bureauCustomerIdToVerify.push(item.bureauCustomerId)
      var params = {
        importSessionId: this.importSessionId,
        bureauCustomerIds: bureauCustomerIdToVerify
      }
      await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer/verifyImportedBureauCustomersSubset', params, { showload: true })
        .then(response => {
          if (response.data.toastType === 2) {
            this.$toastr.s(response.data.toastMessage)
          } else {
            this.$toastr.e(response.data.toastMessage)
          }
          this.load()
        })
      await this.getFileDetails()
    },
    onImportSingle: async function (item) {
      var custname = item.name
      var result = await swal.fire({
        title: 'Bureau Customer Import',
        text: 'Are you sure you want to import only ' + custname + '?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      var WritebackBureauCustomerImportIds = []
      WritebackBureauCustomerImportIds.push(item.bureauCustomerId)
      var params = {
        importSessionId: this.importSessionId,
        bureauCustomerIds: WritebackBureauCustomerImportIds,
        writebackAll: false
      }

      if (result) {
        await this.PerformWriteback(params)
      }
      await this.getFileDetails()
      this.BureauCustomerImportIds = []
      this.checkboxesSelected = false
    },
    onVerifySelectedRecords: async function () {
      if (this.BureauCustomerImportIds.length === 0) { return }

      var params = {
        importSessionId: this.importSessionId,
        bureauCustomerIds: this.BureauCustomerImportIds
      }
      await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer/verifyImportedBureauCustomersSubset', params, { showload: true })
        .then(response => {
          if (response.data.toastType === 2) {
            this.$toastr.s(response.data.toastMessage)
          } else {
            this.$toastr.e(response.data.toastMessage)
          }
          this.load()
        })
      await this.getFileDetails()
    },
    onImportSelectedRecords: async function () {
      if (this.BureauCustomerImportIds.length === 0) { return }

      var result = await swal.fire({
        title: 'Bureau Customer Import',
        text: 'Are you sure you want to import the selected records?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      var params = {
        importSessionId: this.importSessionId,
        bureauCustomerIds: this.BureauCustomerImportIds,
        writebackAll: false
      }

      if (result) {
        await this.PerformWriteback(params)
      }
      await this.getFileDetails()
    },

    onVerifyRecords: async function () {
      await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureaucustomer/verifyImportedBureauCustomers/' + this.importSessionId, { showload: true })
        .then(response => {
          if (response.data.toastType === 2) {
            this.$toastr.s(response.data.toastMessage)
          } else {
            this.$toastr.e(response.data.toastMessage)
          }
          this.load()
        })
      await this.getFileDetails()
    },

    onImportClick: async function () {
      var result = await swal.fire({
        title: 'Complete Bureau Customer Import',
        text: 'Are you sure you want to import these bureau customers?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })
      var params = {
        importSessionId: this.importSessionId,
        bureauCustomerIds: [],
        writebackAll: true
      }

      if (result) {
        await this.PerformWriteback(params)
        if (this.TotalRecordsVerified === 0 && this.TotalRecordsNeedVerification === 0 && this.TotalRecordsDeleted === 0) {
          this.$router.push({ name: 'ImportBureauCustomers' })
        }
      }
    },

    PerformWriteback: async function (params) {
      await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer/writebackBureauCustomers', params, { showload: true })
        .then(response => {
          if (response.data.toastType === 2) {
            this.$toastr.s(response.data.toastMessage)
          } else {
            this.$toastr.e(response.data.toastMessage)
          }
          this.load()
          //          this.$router.push({ name: 'ImportBureauCustomerVerify', params: { id: this.importSessionId } })
        })
      await this.getFileDetails()
    },

    // other methods
    async load () {
      try {
        this.isTableLoading = true
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer/getbureaucustomerverificationrecordsquery', { params: { ...this.buildGoodTableQuery(), importsessionid: this.importSessionId }, showload: true })
        this.rows = response.data.data
        this.totalRecords = response.data.meta.totalItems
        this.BureauCustomerImportIds = []
        this.checkboxesSelected = false
      } catch { } finally {
        this.isTableLoading = false
        this.BureauCustomerImportIds = []
        this.checkboxesSelected = false
      }
    },

    async onDeleteClick () {
      var result = await swal.fire({
        title: 'Delete Customer Import',
        text: 'Are you sure you want to delete the queued Bureau Customers? Already imported customers from this file will remain in Paygate',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: colours.danger,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
      })

      if (result) {
        try {
          // Delete from database and file system
          var response = await axios.delete(process.env.VUE_APP_BUREAU_API_URL + 'bureaucustomer/deleteImportFile/' + this.importSessionId, { showload: true })
          if (response.data.toastType === 2) {
            this.$router.push({ name: 'ImportBureauCustomers' })
            this.$toastr.s(response.data.toastMessage)
          } else {
            this.$toastr.e(response.data.toastMessage)
          }
        } catch (e) {
          this.$toastr.e(e.response.data, e.response.statusText)
          this.loading = false
        }
      }
    },

    async loadOLD () {
      this.isLoading = true
      var query = {
        params: {
          fields: 'name,serviceusernumber,errormessages,bureaucustomerid,errorlevel,deleted,accountnumber,sortcode,ImportSession_Id,problemfields,verifyRequired,bureauImportStatus'
        }
      }
      query.params.paygateId = this.$store.state.common.paygateId
      query.params.sort = this.serverParams.sort.reduce((a, s) => a + `${s.field}:${s.type},`, '')
      query.params.perPage = this.serverParams.perPage
      query.params.page = this.serverParams.currentPage
      query.params.importSessionId = this.importSessionId
      if (this.serverParams.searchKey !== '') {
        query.params.searchFilter = this.serverParams.searchKey
      }
      try {
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer/getbureaucustomerverificationquery', query, { showload: true })
        this.bureauCustomerData = response.data.data
        this.rows = response.data.data
        this.totalRecords = response.data.meta.totalItems
        this.totalPages = response.data.meta.totalPages
        this.currentPage = 1
        this.isLoading = false
      } catch (e) {
        this.$toastr.e(e.response.data, e.response.statusText)
        this.isLoading = false
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
