<template>
  <div>
    <folderBrowser v-if="showFolderBrowser" @close="showFolderBrowser = false; closeFolderPopup()">
    </folderBrowser>
    <h3>
      BACS Bureau Report Download
      <span class="pull-right">
        <a :href="`${docUrl}/automation/paygate-workflow/workflow-nodes/bureauautomation/bureaureports/`" target="_blank">
          <i class="far fa-question-circle"></i>
        </a>
      </span>
    </h3>
    <hr />
    <!-- <br />
    <div class="form-group">
      <label>Group</label>
      <br />
      <group-select v-model="selectedNode.props.s1.value" :groups="options" :class="{invalid: $v.selectedNode.props.s1.value.$error}"></group-select>
      <p
        class="validationText"
        v-if="!$v.selectedNode.props.s1.value.required && $v.selectedNode.props.s1.value.$dirty "
      >A valid PayGate group must be selected!</p>
    </div> -->
    <br />
    <div class="form-group">
      <label>Report Service</label>
      <br />
      <p-radio value="BACS" color="primary" v-model="selectedNode.props.s5.value">BACS</p-radio>
      <p-radio
        value="Faster Payments"
        color="primary"
        disabled
        v-model="selectedNode.props.s5.value"
      >Faster Payments</p-radio>
    </div>
    <br />
    <div class="row">
      <div class="col-md-3">
        <div class="form-group" v-if="selectedNode.props.s5.value === 'BACS'">
          <div class="pretty p-icon p-smooth">
            <input type="checkbox" v-model="selectAll" @change="selectAllClick" />
            <div class="state p-primary">
              <i class="icon fa fa-check"></i>
              <label>Report Type</label>
            </div>
          </div>
        </div>
        <div v-else>
          <label>Report Type</label>
        </div>
      </div>
    </div>
      <div v-if="selectedNode.props.s5.value === 'BACS'">
        <div class="row">
          <div class="col-md-3">
            <div class="pretty p-icon p-smooth">
              <input type="checkbox" v-model="selectedNode.props.b2.value" @change="checked" />
              <div class="state p-primary">
                <i class="icon fa fa-check"></i>
                <label>ADDACS Report</label>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="pretty p-icon p-smooth">
              <input type="checkbox" v-model="selectedNode.props.b3.value" @change="checked"  />
              <div class="state p-primary">
                <i class="icon fa fa-check"></i>
                <label>ARUCS Report</label>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="pretty p-icon p-smooth">
              <input type="checkbox" v-model="selectedNode.props.b4.value" @change="checked"  />
              <div class="state p-primary">
                <i class="icon fa fa-check"></i>
                <label>ARUDD Report</label>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-3">
            <div class="pretty p-icon p-smooth">
              <input type="checkbox" v-model="selectedNode.props.b5.value" @change="checked"  />
              <div class="state p-primary">
                <i class="icon fa fa-check"></i>
                <label>AUDDIS Report</label>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="pretty p-icon p-smooth">
              <input type="checkbox" v-model="selectedNode.props.b6.value" @change="checked"  />
              <div class="state p-primary">
                <i class="icon fa fa-check"></i>
                <label>AWACS Report</label>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="pretty p-icon p-smooth">
              <input type="checkbox" v-model="selectedNode.props.b7.value" @change="checked"  />
              <div class="state p-primary">
                <i class="icon fa fa-check"></i>
                <label>DDIC Advice Reports</label>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-3">
            <div class="pretty p-icon p-smooth">
              <input type="checkbox" v-model="selectedNode.props.b8.value" @change="checked"  />
              <div class="state p-primary">
                <i class="icon fa fa-check"></i>
                <label>Arrival Report</label>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="pretty p-icon p-smooth">
              <input type="checkbox" v-model="selectedNode.props.b9.value" @change="checked"  />
              <div class="state p-primary">
                <i class="icon fa fa-check"></i>
                <label>Withdrawal Report</label>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="pretty p-icon p-smooth">
              <input type="checkbox" v-model="selectedNode.props.b14.value" @change="checked"  />
              <div class="state p-primary">
                <i class="icon fa fa-check"></i>
                <label>DDIC Reports</label>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div class="row">
          <div class="col-md-3">
            <div class="pretty p-icon p-smooth">
              <input type="checkbox" v-model="selectedNode.props.b11.value" @change="checked"  />
              <div class="state p-primary">
                <i class="icon fa fa-check"></i>
                <label>Component History Report</label>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="pretty p-icon p-smooth">
              <input type="checkbox" v-model="selectedNode.props.b12.value" @change="checked"  />
              <div class="state p-primary">
                <i class="icon fa fa-check"></i>
                <label>Test Report</label>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="pretty p-icon p-smooth">
              <input type="checkbox" v-model="selectedNode.props.b10.value" @change="checked"  />
              <div class="state p-primary">
                <i class="icon fa fa-check"></i>
                <label>Input Report</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row">
          <div class="col-md-3">
            <div class="pretty p-icon p-smooth">
              <input type="checkbox" v-model="selectedNode.props.b10.value" />
              <div class="state p-primary">
                <i class="icon fa fa-check"></i>
                <label>DCA Input Report</label>
              </div>
            </div>
          </div>
          <div class="col-md-3"></div>
          <div class="col-md-3"></div>
        </div>
      </div>
    <br />
    <div class="form-group">
      <label>Report Format</label> <span class="text-muted ml-2">Note, The BACS service does not provide reports in all formats.</span>
      <br />
      <div v-if="selectedNode.props.s5.value === 'BACS'">
        <p-radio value="XML" color="primary"  v-model="selectedNode.props.s2.value">XML</p-radio>
        <p-radio value="HTML" color="primary" v-model="selectedNode.props.s2.value">HTML</p-radio>
        <p-radio value="PDF" color="primary"  v-model="selectedNode.props.s2.value">PDF</p-radio>
        <p-radio value="CSV" color="primary"  v-model="selectedNode.props.s2.value">CSV</p-radio>
        <p-radio value="All" color="primary"  v-model="selectedNode.props.s2.value">All</p-radio>
      </div>
      <div v-else>
        <p-radio value="XML" color="primary"  v-model="selectedNode.props.s2.value">XML</p-radio>
        <p-radio value="CSV" color="primary"  v-model="selectedNode.props.s2.value">CSV</p-radio>
        <p-radio value="BOTH" color="primary" v-model="selectedNode.props.s2.value">Both</p-radio>
      </div>
    </div>
    <br />
    <label class="required">Save Path</label>
    <div class="input-group mb-3">
      <input
        type="text"
        class="form-control"
        :class="{invalid: $v.selectedNode.props.s3.value.$error}"
        @input="$v.selectedNode.props.s3.value.$touch()"
        v-model="selectedNode.props.s3.value"
      />
      <div class="input-group-append">
        <button
          class="btn btn-outline-secondary"
          @click.prevent="btnFolderBrowser"
          type="button"
        >...</button>
      </div>
    </div>
    <p
      class="validationText"
      v-if="!$v.selectedNode.props.s3.value.required && $v.selectedNode.props.s3.value.$dirty "
    >The Save Folder field is required!</p>
    <p
      class="validationText"
      v-if="!$v.selectedNode.props.s3.value.maxLength"
    >Maximum length is {{ $v.selectedNode.props.s3.value.$params.maxLength.max }} characters!</p>
    <br />
    <div class="form-group">
      <label>Report Age</label>
      <br />
      <select class="form-control" v-model="selectedNode.props.s4.value" v-if="selectedNode.props.s5.value=='BACS'">
        <option>Current Day</option>
        <option>Last Two Days</option>
        <option>Last Two Days</option>
        <option>Last Week</option>
        <option>Last Month</option>
      </select>
      <select class="form-control" v-model="selectedNode.props.s4.value" v-else>
        <option>Current Day</option>
        <option>Last Two Days</option>
        <option>Last Three Days</option>
        <option>Last Four Days</option>
        <option>Last Five Days</option>
      </select>
    </div>
    <br />
    <div class="form-group">
      <p-check
        name="check"
        class="p-switch"
        color="primary"
        v-model="selectedNode.props.b1.value"
      >Download previously accessed reports</p-check>
    </div>
    <div class="form-group">
      <p-check
        name="check"
        class="p-switch"
        color="primary"
        v-model="selectedNode.props.b16.value"
      >Download each SUN's reports into a separate folder</p-check>
    </div>
    <div class="form-group">
      <p-check
        name="check"
        class="p-switch"
        color="primary"
        v-model="selectedNode.props.b17.value"
      >Prefix downloaded report filenames with the associated SUN</p-check>
    </div>
    <br>
    <div class="form-group">
      <label>Service User Numbers</label><span class="text-muted ml-2">A comma separated or list of individual SUNS</span>
      <span><button @click="UseSunsFromBureauCustomers" class="btn btn-outline-dark btn-sm float-right mb-2">Use SUNs from Bureau Customers</button></span>
      <br />
      <b-form-textarea
        id="sunsTextArea"
        v-model="selectedNode.props.s1.value"
        rows="10"
      ></b-form-textarea>
      <!-- <p
      class="validationText"
      v-if="!$v.selectedNode.props.s1.value.required && $v.selectedNode.props.s1.value.$dirty "
    >At least one SUN is required!  Supported formats are comma or newline separated.</p> -->
    <!-- <p
      class="validationText"
      v-if="!$v.selectedNode.props.s1.value.maxLength"
    >Maximum length is {{ $v.selectedNode.props.s1.value.$params.maxLength.max }} characters!</p> -->
    </div>
    <hr />
    <div class="pull-right">
      <b-button variant="danger" class="btnPad" @click.prevent="btnCancel">Cancel</b-button>
      <b-button class variant="success" @click.prevent="btnSave">OK</b-button>
    </div>
  </div>
</template>

<script>
import * as diagram from './../../Engine/workflowDesigner.js'
import axios from 'axios'
import { required, maxLength } from 'vuelidate/lib/validators'
import folderBrowser from '@/Views/Workflow/Shared/FolderBrowser.vue'
const utils = require('@/Components/Workflow/Utils/fileUtils.js')
export default {
  components: {
    folderBrowser
  },
  validations: {
    selectedNode: {
      props: {
        // s1: {
        //   value: {
        //     required,
        //     maxLength: maxLength(4096)
        //   }
        // },
        s3: {
          value: {
            required,
            maxLength: maxLength(128)
          }
        }
      }
    }
  },
  data () {
    return {
      selectAll: false,
      showFolderBrowser: false,
      options: [],
      selectedNode: {},
      rollbackState: '',
      docUrl: process.env.VUE_APP_DOCUMENTATION_ROOT_URL
    }
  },
  methods: {
    UseSunsFromBureauCustomers: async function () {
      // Get a list of Suns in use by all Bureau Customers for this customer.
      let res
      try {
        res = await axios.get(`${process.env.VUE_APP_WORKFLOWAPI_API_URL}workflow/bureau/suns`)
        if (res) {
          if (res.data.suns) {
            this.selectedNode.props.s1.value = res.data.suns
          }
        }
      } catch (e) {
        if (e.response && e.response.status === 403) {
          this.$snapbar.e('You are not authorised to view Bureau Customer SUNS.')
        } else {
          this.$snapbar.e(`Error calling the Workflow API - ${e.message}`)
        }
      }
    },
    btnSave: function () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.selectedNode.props.s3.value = utils.sanitisePath(this.selectedNode.props.s3.value)
      this.$store.commit('setPopupState', false)
    },
    btnCancel: function () {
      this.$store.commit('setPopupState', false)
      // Restore the original state
      const initialPropState = JSON.parse(this.rollbackState)
      this.selectedNode.props = initialPropState
      this.$nextTick()
    },
    fpsClicked: function () {
      console.log('click')
    },
    btnFolderBrowser () {
      this.$store.commit('setModalPopupString', this.selectedNode.props.s3.value)
      this.showFolderBrowser = true
    },
    closeFolderPopup () {
      console.log('closeFolderPopup popup - updating parent')
      console.log('state: ' + this.$store.state.mapping.modalPopupString)
      this.selectedNode.props.s3.value = this.$store.state.mapping.modalPopupString
    },
    selectAllClick () {
      console.log(this.selectAll)
      this.selectedNode.props.b2.value = this.selectAll
      this.selectedNode.props.b3.value = this.selectAll
      this.selectedNode.props.b4.value = this.selectAll
      this.selectedNode.props.b5.value = this.selectAll
      this.selectedNode.props.b6.value = this.selectAll
      this.selectedNode.props.b7.value = this.selectAll
      this.selectedNode.props.b8.value = this.selectAll
      this.selectedNode.props.b9.value = this.selectAll
      this.selectedNode.props.b10.value = this.selectAll
      this.selectedNode.props.b11.value = this.selectAll
      this.selectedNode.props.b12.value = this.selectAll
      this.selectedNode.props.b14.value = this.selectAll
    },
    checked () {
      console.log('checked()')
      this.selectAllStartState()
    },
    selectAllStartState () {
      if (
        this.selectedNode.props.b2.value &&
        this.selectedNode.props.b3.value &&
        this.selectedNode.props.b4.value &&
        this.selectedNode.props.b5.value &&
        this.selectedNode.props.b6.value &&
        this.selectedNode.props.b7.value &&
        this.selectedNode.props.b8.value &&
        this.selectedNode.props.b9.value &&
        this.selectedNode.props.b10.value &&
        this.selectedNode.props.b11.value &&
        this.selectedNode.props.b12.value &&
        this.selectedNode.props.b14.value) {
        this.selectAll = true
      } else {
        this.selectAll = false
      }
    }
  },
  created: async function () {
    // this.paygateId = this.$store.getters.getClaim('paygate_id').value
  },
  beforeMount () {
    this.selectedNode = this.$store.state.workflow.selectedNode
    console.log(this.selectedNode)
    this.rollbackState = JSON.stringify(this.selectedNode.props)
    this.selectAllStartState()
  },
  destroyed () {
    // Switch the clipboard event listener back on for the diagram
    window.onkeydown = diagram.keyPressed
  },
  watch: {
    selectedNode: {
      handler: function (val, oldVal) {
        // Used a deep watch because p-radio does not support an @click event.
        if (val.props.s5.value === 'Faster Payments') {
          // Prevent selecting an unsupported report format in Faster Payments.
          if (this.selectedNode.props.s2.value !== 'XML' && this.selectedNode.props.s2.value !== 'CSV' && this.selectedNode.props.s2.value !== 'BOTH') {
            this.selectedNode.props.s2.value = 'XML'
          }
        } else {
          if (this.selectedNode.props.s2.value !== 'XML' && this.selectedNode.props.s2.value !== 'HTML' && this.selectedNode.props.s2.value !== 'PDF' && this.selectedNode.props.s2.value !== 'CSV' && this.selectedNode.props.s2.value !== 'All') {
            this.selectedNode.props.s2.value = 'XML'
          }
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
  .invalid {
    border-color: red;
    border-width: 2px;
  }
</style>
