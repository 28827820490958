<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Bureau Customers
          <help-icon :docPath="'/bacsbureau/bureaucustomers/'"></help-icon>
          <favourite-icon></favourite-icon>
        </h2>
      </div>
      <div class="idb-block-content">
        <vue-good-table
          :paginationOptions="paginationOptions"
          :sort-options="sortOptions"
          :isLoading.sync="isTableLoading"
          :rows="rows"
          :columns="columns"
          :lineNumbers="true"
          mode="remote"
          :totalRows="totalRecords"
          @on-row-click="onRowClick"
          @on-page-change="onPageChange"
          @on-sort-change="onSortChange"
          @on-column-filter="onColumnFilter"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          styleClass="vgt-table striped bordered"
          ref="table"
        >
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
              <span class="sr-only">Clear filters</span>
            </button>
            <b-button
              @click.prevent="load"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Refresh the data in the table'"
            >
              <i class="fa fa-sync pointer dimmedIcon"></i>
              <span class="sr-only">Refresh table</span>
            </b-button>
            <b-button
              @click.prevent="exportTable"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Export the contents of the table'"
            >
              <i class="fa fa-file-export pointer dimmedIcon"></i>
              <span class="sr-only">Export the contents of the table</span>
            </b-button>
            <b-button
              @click.prevent="createCustomer"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Create Bureau Customer'"
            >
              <i class="fa fa-plus pointer dimmedIcon"></i>
              <span class="sr-only">Create Bureau Customer</span>
            </b-button>
          </div>
        </vue-good-table>
      </div>
      <div class="idb-block-footer">
        <b-btn id="addCustomerButton" type="link" variant="primary" @click="createCustomer">
          <i class="fa fa-plus mr-2"></i>Create Bureau Customer
        </b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import papa from "papaparse";

export default {
  mixins: [tableFilterMixin],
  computed: {
    ...mapGetters(['selectedCustomer'])
  },
  data () {
    return {
      rows: [],
      columns: [
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Name'
          }
        },
        {
          label: 'Service User Number',
          field: 'serviceUserNumber',
          filterOptions: {
            enabled: true,
            placeholder: 'Service User Number'
          }
        }
      ],
      serverParams: {
        sort: [{ field: 'name', type: 'asc' }]
      }
    }
  },
  watch: {
    selectedCustomer () { this.load() }
  },
  methods: {
    async exportTable () {
      var query = this.buildGoodTableQuery()
      query.perPage = this.totalRecords
      query.forExport = true
      const response = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureauCustomer`, { params: query }, { showload: true })
      var rows = response.data
      var csvString = papa.unparse(rows)
      var blob = new Blob([csvString])
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, 'bureau-customers.csv')
      } else {
        var a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
        a.download = 'bureau-customers.csv'
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
      }
    },
    onRowClick (params) {
      var id = params.row.bureauCustomerId
      this.$router.push({ name: 'BureauCustomerDetails', params: { id: id } })
    },
    load: _.debounce(async function () {
      try {
        this.isTableLoading = true
        var params = { ...this.buildGoodTableQuery(), paygateid: this.selectedCustomer }

        var response = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureauCustomer`,
          {
            params,
            showerror: true,
            errormessage: 'Failed loading bureau customers'
          })

        this.rows = response.data.data
        this.totalRecords = response.data.meta.totalItems
      } catch { } finally {
        this.isTableLoading = false
      }
    }, 500),
    createCustomer () {
      this.$router.push({ name: 'BureauCustomerCreate', params: {} })
    }
  }
}

</script>
