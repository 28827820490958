<template>
	<transition name="modal">
		<div class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-container">
					<slot name="header">
						<div>
							<b-button variant="link" size="lg" class="pull-right close-btn" @click="cancelPopup">
								<span class="fa fa-times" aria-hidden="true"></span>
							</b-button>
							<h3>File Browser</h3>
						</div>
					</slot>
					<div class="row" v-if="errorMessage !==''">
						<div class="col-md-12 text-danger">{{errorMessage}}</div>
					</div>
					<div class="modal-body">
						<slot name="body">
							<div class="row" v-if="errorMessage===''">
								<div class="col-md-12">
									<span>
										<button
											@click="btnRefresh"
											class="btn btn-link dimmedIcon"
											v-b-popover.hover.top.d500="'Refresh Folders'"
										>
											<i class="fas fa-sync"></i>
										</button>
									</span>
									<span class="mr-2">Path</span>
									<span>{{selectedFolder}}</span>
								</div>
							</div>
							<hr />
							<br />
							<div class="row">
								<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
									<div class="row">
										<div class="col-md-6 folderPanel">
											<VuePerfectScrollbar class="scroll-area">
												<v-jstree
													:data="treeData"
													drag-over-background-color="#F4E3EE"
													size="large"
													@item-click="itemClick"
												></v-jstree>
											</VuePerfectScrollbar>
										</div>
										<div class="col-md-6 folderPanel">
											<VuePerfectScrollbar class="scroll-area">
												<div class>
													<div
														v-for="(element, index) in myArray"
														:key="index"
														class="folderManagerClickable"
														v-on:click="selectItem(element)"
														v-on:dblclick="selectItemAndClose(element)"
													>
														<div class="list-group-item">
															<div class="d-flex justify-content-start">
																<div class="d-flex align-items-start flex-column mr-20">
																	<i v-if="element.isFolder" class="fa fa-folder fa-4x folderManagerFolder"></i>
																	<i v-else class="fa fa-file fa-4x folderManagerFile"></i>
																</div>
																<div class="d-flex align-items-start flex-column pt-10">
																	<h6 class="font-lg">{{element.text}}</h6>
																	<p class="mb-0">{{element.description}}</p>
																	<div v-if="element.isFolder" class="small fw-bold"></div>
																	<div v-if="!element.isFolder" class="small fw-bold">
																		<strong>Size:</strong>
																		{{bytesToSize(element.stat.size)}}
																	</div>
																	<div v-if="!element.isFolder" class="small fw-bold">
																		<strong>Last Modified:</strong>
																		{{reformatDate(element.stat.mtime)}}
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</VuePerfectScrollbar>
										</div>
									</div>
								</div>
							</div>
						</slot>
					</div>
					<div class="modal-footer">
						<slot name="footer">
							<b-button variant="danger" @click="cancelPopup">
								<!-- <span class="fa fa-times" aria-hidden="true"></span> -->
								Cancel
							</b-button>
							<button @click="selectAndClose" class="btn btn-success">
								<span class aria-hidden="true"></span>Ok
							</button>
						</slot>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import axios from 'axios'
import VJstree from 'vue-jstree'
export default {
	name: 'Popup',
	components: {
		VJstree,
		VuePerfectScrollbar
	},
	// props: ['schema', 'value'],
	props: ['property', 'groupId'],
	data () {
		return {
			basePath: '/',
			initialSelected: '',
			treeData: [],
			selectedId: null,
			selectedFolder: '',
			selectedFile: '',
			selectedItem: undefined,
			myArray: [
			],
			settings: {},
			errorMessage: ''
		}
	},
	methods: {
		async btnRefresh () {
			await this.loadFolderTree()
			await this.getFolderData(this.selectedFolder, true)
		},
		selectAndClose () {
			console.log(this.selectedItem)
			console.log(this.initialSelected)
			console.log(this.selectedFolder)
			if (this.selectedItem) {
				this.$store.commit('setModalPopupString', this.selectedItem.path)
				this.$emit('close')
			} else {
				this.$store.commit('setModalPopupString', this.initialSelected)
				this.$emit('close')
			}
		},
		cancelPopup () {
			this.$store.commit('setModalPopupString', this.initialSelected)
			this.$emit('close')
		},
		closePopup () {
			console.log('closePopup()')
			console.log('this.selectedFolder:', this.selectedFolder)
			this.$store.commit('setModalPopupString', this.selectedFolder)
			this.$emit('close')
		},
		async selectItem (e) {
			const postData = {
				path1: this.basePath,
				path2: e.path
			}

			// Fix for when the root is selected, the path gets duplicated.
			if (postData.path2.startsWith(this.basePath)) {
				postData.path2 = e.path.replace(this.basePath, "")
			}

			let joinedPath
			try {
				joinedPath = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}pathjoiner`, postData)
			} catch (e) {
				this.errorMessage = 'Path Error'
				return
			}
			if (joinedPath && joinedPath.data && joinedPath.data.path) {
				this.selectedFolder = joinedPath.data.path
			}
			this.selectedItem = e
		},
		async selectItemAndClose (e) {
			// Double click - RH Panel
			console.log('selectItemAndClose()')
			console.log(e)

			const postData = {
				path1: this.basePath,
				path2: e.path
			}

			// Fix for when the root is selected, the path gets duplicated.
			if (postData.path2.startsWith(this.basePath)) {
				postData.path2 = e.path.replace(this.basePath, "")
			}

			let joinedPath
			try {
				joinedPath = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}pathjoiner`, postData)
			} catch (e) {
				this.errorMessage = 'Path Error.'
				return
			}

			if (joinedPath.data.path && joinedPath.data.path && joinedPath.data.path) {
				this.selectedItem = joinedPath.data.path
			}

			// Is it a file or a folder?
			if (e.isFolder) {
				this.selectedFolder = e.path
				return
			}

			// this.selectedFolder = e.path
			this.selectedFolder = joinedPath.data.path
			this.closePopup()
		},
		async loadFolderTree () {
			try {
				let res
				if (this.groupId) {
					res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}folderStructure/folders/${this.groupId}`)
				} else {
					res = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}folderStructure/folders`)
				}
				if (res.data.response) {
					if (Array.isArray(res.data.response)) {
						this.treeData = res.data.response
					}
				}
			} catch (e) {
				if (e.response && e.response.status === 403) {
					this.errorMessage = 'Error: You are not authorised to view the customer file area.'
				} else {
					this.errorMessage = 'Error: Cannot show the customer file area.'
				}
			}
		},
		async getFolderData (folder, isRefresh = false) {
			console.log('folder:', folder)
			const postData = {
				folder,
				groupId: this.groupId,
				isRefresh
			}
			console.log(folder)
			try {
				const res = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}FolderContents`, postData)
				this.myArray = res.data.response
			} catch (e) {
				if (e.response && e.response.status === 403) {
					this.$snapbar.e('Authorisation error.')
				} else if (e.response && e.response.status === 400) {
					let msg = 'e.message'
					if (e.response.data) {
						msg = e.response.data
					}
					this.errorMessage = `Could not get folder data - ${msg}`
				} else {
					this.errorMessage = 'Could not get folder data'
				}
			}
		},
		async itemClick (node, item, e) {
			// LH Panel
			console.log(node)
			this.basePath = this.treeData[0].path
			const postData = {
				path1: this.basePath,
				path2: node.model.path
			}

			// Fix for when the root is selected, the path gets duplicated.
			if (this.basePath === node.model.path) {
				postData.path2 = '/'
			}

			console.log(postData)
			let joinedPath
			try {
				joinedPath = await axios.post(`${process.env.VUE_APP_WORKFLOW_API_URL}pathjoiner`, postData)
			} catch (e) {
				this.errorMessage = 'Path Error'
				return
			}
			if (joinedPath && joinedPath.data && joinedPath.data.path) {
				this.selectedFolder = joinedPath.data.path
				if (this.selectedItem === undefined) {
					this.selectedItem = {}
				}
				this.selectedItem.isFolder = true
				this.selectedItem.path = this.selectedFolder
				this.selectedItem.text = this.selectedFolder
			}
			this.getFolderData(node.model.path)

			// Double click - if so select and close?
			if (e.detail === 2) {
				this.closePopup()
			}
		},
		fileClick (e) {
			this.selectedItem = e
		},
		bytesToSize (bytes) {
			const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
			if (bytes === 0) return '0 bytes'
			const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
			if (i === 0) return `${bytes} ${sizes[i]}`
			return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
		},
		reformatDate (d) {
			const myDate = new Date(d)
			let dd = myDate.getDate()
			let mm = myDate.getMonth() + 1
			const yyyy = myDate.getFullYear()
			if (dd < 10) {
				dd = '0' + dd
			}
			if (mm < 10) {
				mm = '0' + mm
			}
			let h = myDate.getHours()
			let m = myDate.getMinutes()
			let s = myDate.getSeconds()
			const ms = this.pad(myDate.getMilliseconds(), 3, '0')
			h = this.checkTime(h)
			m = this.checkTime(m)
			s = this.checkTime(s)
			return dd + '/' + mm + '/' + yyyy + ' - ' + h + ':' + m + ':' + s
		},
		checkTime (i) {
			if (i < 10) {
				i = '0' + i
			}
			return i
		},
		pad (n, width, z) {
			z = z || '0'
			n += ''
			return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n
		}
	},
	created: async function () {
		await this.loadFolderTree()
		this.selectedFolder = this.$store.state.mapping.modalPopupString
		this.initialSelected = this.$store.state.mapping.modalPopupString
		// console.log('this.selectedFolder:', this.selectedFolder)
		await this.getFolderData(this.selectedFolder)
	}
}
</script>

<style scoped>
.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

.modal-container {
	width: 80%;
	margin: 0px auto;
	padding: 20px 30px;
	background-color: #fff;
	border-radius: 2px;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
	transition: all 0.3s ease;
	font-family: Helvetica, Arial, sans-serif;
	overflow-y: auto;
	max-height: 90vh;
}

.modal-header h3 {
	margin-top: 0;
	color: #42b983;
}

.modal-body {
	margin: 20px 0;
}

.modal-default-button {
	float: right;
}

.modal-enter,
.modal-leave {
	opacity: 0;
}

.modal-enter .modal-container,
.modal-leave .modal-container {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

/* .folderManagerFolderDivider {
    border-right: 1px solid #dddddd
  }
  .folderManagerFolder {
    color: #FFE89F;
    text-shadow: 1px 1px 1px #ccc;
  }
  .treeFolder {
    color: #F1CE6B;
  }
  .folderManagerFile {
      color: #cccccc;
      text-shadow: 1px 1px 1px #aaaaaa;
  }

  .folderManagerClickable {
    cursor: pointer;
  }
  .folderManagerClickable div:hover {
    background: #e5e5e5;
  }
  .folderManagerClickable div:active {
    background: #dddddd;
  }
  .folderManagerSelectedItem {
    background: #eeeeee;
    border-color: #eeeeee;
    border-style: none none none solid;
    border-left-width: 12px;
  }
  .folderManagerSelectedItem div:hover {
    background: #eeeeee;
  }
  .list-group-item {
    border-style: none none none solid;
  }
  .tree-anchor {
    background: #ff0000;
  }
  .tree-anchor:hover {
    background: #e5e5e5;
  } */
.folderManagerFolderDivider {
	border-right: 1px solid #dddddd;
}
.folderManagerFolder {
	color: #ffe89f;
	text-shadow: 1px 1px 1px #ccc;
}
.treeFolder {
	color: #f1ce6b;
}
.folderManagerFile {
	color: #cccccc;
	text-shadow: 1px 1px 1px #aaaaaa;
}

.folderManagerClickable {
	cursor: pointer;
}
.folderManagerClickable div:hover {
	background: #e5e5e5;
}
.folderManagerClickable div:active {
	background: #dddddd;
}
.folderManagerSelectedItem {
	background: #eeeeee;
	border-color: #eeeeee;
	border-style: none none none solid;
	border-left-width: 12px;
}
.folderManagerSelectedItem div:hover {
	background: #eeeeee;
}
.list-group-item {
	border-style: none none none solid;
}
.tree-anchor:hover {
	background: #e5e5e5;
}
.close-btn {
	color: #999999;
}
.scroll-area {
	height: calc(100% - 0px);
	min-height: unset;
}
.folderPanel {
	height: 550px;
	min-height: 400px;
}
</style>
