<template>
  <div>
    <!-- <loading v-bind:loading="loading"></loading> -->
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          {{currentPage}}Bureau Customer
          <help-icon :docPath="'/bacsbureau/bureaucustomers/'"></help-icon>
          <favourite-icon></favourite-icon>
        </h2>
      </div>

      <div class="idb-block-content">
        <b-card id="noBankLabel"
          v-if="BankDetailWarning && hasCustomerId"
          bg-variant="warning"
          text-variant="white"
          style="margin-bottom: 0.8em; margin-right: 0.8em"
        >No bank details have been provided for this bureau customer. This bureau customer can still be used for processing but bank details will need to be provided at submission stage.</b-card>
        <b-card id="inUseLabel"
          v-if="currentSubmissionCount > 0"
          bg-variant="warning"
          text-variant="white"
          style="margin-bottom: 0.8em; margin-right: 0.8em"
        >This bureau customer is currently being used as part of a submission. No changes can be made until the submission is completed or cancelled.</b-card>
        <div id="pendingActionLabel"
          class="alert alert-warning"
          v-if="bureauCustomer.isItemActioned"
        >This bureau customer has a pending action against it and cannot be edited</div>
        <b-tabs>
          <b-tab id="detailsTab" title="Bureau Customer Details" active>
            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="bureauCustomerReference">Unique Customer Reference</label>
              </b-col>
              <b-col sm="3">
                <b-form-input
                  id="bureauCustomerReference"
                  type="text"
                  readonly
                  v-model.trim="bureauCustomer.customerReference"
                  :disabled="bureauCustomer.isItemActioned"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label class="required" for="bureauCustomerName">Name</label>
              </b-col>
              <b-col sm="8">
                <b-form-input id="bureauCustomerName"
                  type="text"
                  :maxlength="50"
                  v-model.trim="$v.bureauCustomer.name.$model"
                  :disabled="bureauCustomer.isItemActioned"
                              @input="checkName"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-col offset-sm="3" class="pl-3" v-if="$v.bureauCustomer.name.$dirty">
              <label id="bureauCustomerNameRequired"
                class="text-danger small"
                v-if="!$v.bureauCustomer.name.required"
              >A Bureau Customer Name is required</label>
              <label id="bureauCustomerNameInUse"
                class="text-danger small"
                v-if="nameInUse"
              >This Bureau Customer Name is already in use, provide a new name.</label>
            </b-col>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label class="required" for="serviceUserNumberInput">Service User Number</label>
              </b-col>
              <b-col sm="3">
                <b-form-input id="serviceUserNumberInput"
                  type="text"
                  :maxlength="6"
                  v-model.trim="$v.bureauCustomer.serviceUserNumber.$model"
                  :disabled="bureauCustomer.isItemActioned"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-col offset-sm="3" class="pl-3" v-if="$v.bureauCustomer.serviceUserNumber.$dirty">
              <label
                class="text-danger small" id="serviceUserNumberInputRequired"
                v-if="!$v.bureauCustomer.serviceUserNumber.required"
              >A Service User Number is required</label>
              <label
                class="text-danger small" id="serviceUserNumberInvalid"
                v-if="!$v.bureauCustomer.serviceUserNumber.sunValidator"
              >The Service User Number is not valid</label>
            </b-col>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="defaultImportFilenameInput">Default Import Filename</label>
              </b-col>
              <b-col sm="6">
                <b-form-input id="defaultImportFilenameInput"
                  type="text"
                  :maxlength="250"
                  v-model="bureauCustomer.defaultImportFilename"
                  :disabled="bureauCustomer.isItemActioned"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col sm="9" offset-sm="3">
                <label>
                  <a class="text-primary small" @click="addLookup('[CUSTOMERREF]')">[CUSTOMERREF]</a>
                  will be replaced with Customer Reference
                </label>
              </b-col>
              <b-col sm="9" offset-sm="3">
                <label>
                  <a class="text-primary small" @click="addLookup('[SUN]')">[SUN]</a>
                  will be replaced with customers Service Reference Number
                </label>
              </b-col>
              <b-col sm="9" offset-sm="3">
                <label>
                  <a
                    class="text-primary small"
                    @click="addLookup('[DATETIME, DD-MM-YYYY]')"
                  >[DATETIME, FORMAT]</a>
                  will be replaced with the current formatted date/time
                </label>
              </b-col>
              <b-col sm="9" offset-sm="3">
                <label class="small">* and ? can be used as wildcards</label>
              </b-col>
            </b-row>
            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="defaultImportSchemaInput">Default Import Schema</label>
              </b-col>
              <b-col sm="4">
                <b-form-select id="defaultImportSchemaInput"
                  v-model="bureauCustomer.defaultImportSchema"
                  :disabled="bureauCustomer.isItemActioned"
                  :options="schemas"
                ></b-form-select>
              </b-col>
            </b-row>

            <b-row
              class="mt-2"
              v-if="$v.bureauCustomer.defaultImportSchema.$model == 'Mapping'"
              :class="{ invalid: $v.bureauCustomer.defaultImportMapping.$error }"
            >
              <b-col sm="3" class="mt-2">
                <label
                  for="defaultImportMappingInput"
                  class="required"
                >Default Import Mapping</label>
              </b-col>
              <b-col sm="4">
                <b-form-select id="defaultImportMappingInput"
                  v-model="$v.bureauCustomer.defaultImportMapping.$model"
                  :options="mappings"
                  :disabled="bureauCustomer.isItemActioned"
                ></b-form-select>
                <validation-messages
                  v-model="$v.bureauCustomer.defaultImportMapping"
                  name="Default Import Mapping"
                ></validation-messages>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="contraNarrativeInput">Contra Narrative</label>
              </b-col>
              <b-col sm="4">
                <b-form-input id="contraNarrativeInput"
                  type="text"
                  :maxlength="255"
                  v-model="bureauCustomer.contraNarrative"
                  :disabled="bureauCustomer.isItemActioned"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab id="bankTab">
            <template slot="title">
              Bank Details
              <i
                id="bankDetailsMissing"
                v-if="!$v.bureauCustomer.bankReference.required || !$v.bureauCustomer.sortCode.required || !$v.bureauCustomer.accountNumber.required"
                class="missing-fields text-danger fa fa-exclamation-triangle"
                aria-hidden="true"
                title="Bank details missing"
              ></i>
            </template>

            <!-- Bank Reference -->
            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label
                  :class="bureauCustomer.bankReference === '' && bureauCustomer.sortCode === '' && bureauCustomer.accountNumber === '' ? '' : 'required' "
                  for="bankReferenceInput"
                >Reference</label>
              </b-col>
              <b-col sm="4">
                <b-form-input id="bankReferenceInput"
                  type="text"
                  :maxlength="50"
                  v-model.trim="$v.bureauCustomer.bankReference.$model"
                  :disabled="bureauCustomer.isItemActioned"
                ></b-form-input>
                <!-- Validation -->
                <span v-if="!$v.bureauCustomer.bankReference.required">
                  <small id="bankReferenceInputMissing" class="form-text text-danger small text-nowrap">Reference is required</small>
                </span>
              </b-col>
            </b-row>

            <!-- Sort Code -->
            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="sortCodeInput"
                  :class="bureauCustomer.bankReference === '' && bureauCustomer.sortCode === '' && bureauCustomer.accountNumber === '' ? '' : 'required' "
                >Sort Code</label>
              </b-col>
              <b-col sm="2">
                <the-mask id="sortCodeInput"
                  type="text"
                  class="form-control"
                  v-model.trim="$v.bureauCustomer.sortCode.$model"
                  :disabled="bureauCustomer.isItemActioned"
                  v-focus
                  :mask="['##-##-##']"
                  :guide="true"
                  ref="softCode"
                ></the-mask>
                <!-- Validation -->
                <span v-if="$v.bureauCustomer.sortCode.$dirty">
                  <small id="sortCodeInputRequired"
                    class="form-text text-danger small text-nowrap"
                    v-if="!$v.bureauCustomer.sortCode.required"
                  >Sort Code is required</small>
                  <small id="sortCodeInputShort"
                    class="form-text text-danger small text-nowrap"
                    v-if="!$v.bureauCustomer.sortCode.minLength"
                  >
                    Sort Code must be at least
                    {{ $v.bureauCustomer.sortCode.$params.minLength.min }}
                    numbers
                  </small>
                  <small id="sortCodeInputTooLong"
                    class="form-text text-danger small text-nowrap"
                    v-if="!$v.bureauCustomer.sortCode.maxLength"
                  >
                    Sort Code must be less than
                    {{ $v.bureauCustomer.sortCode.$params.maxLength.max }}
                    numbers
                  </small>
                </span>
              </b-col>
              <span
                v-if="!(bureauCustomer.bankReference === '' && bureauCustomer.sortCode === '' && bureauCustomer.accountNumber === '')"
              >
                <b-col v-if="sortCodeValid===true">
                  <span id="sortCodeInputValid" style="font-size: 200%; color: green" class="fa fa-check-circle"></span>
                </b-col>
                <b-col v-else>
                  <small id="sortCodeInputInvalid"
                    class="form-text text-warning small text-nowrap"
                    v-if="!sortCodeValid && $v.bureauCustomer.sortCode.required"
                  >{{ sortCodeErrorMessage }}</small>
                </b-col>
              </span>
            </b-row>

            <!-- Account Number -->
            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label
                  :class="bureauCustomer.bankReference === '' && bureauCustomer.sortCode === '' && bureauCustomer.accountNumber === '' ? '' : 'required' "
                  for="accountNumberInput"
                >Account Number</label>
              </b-col>
              <b-col sm="2">
                <the-mask id="accountNumberInput"
                  type="text"
                  class="form-control"
                  v-model.trim="$v.bureauCustomer.accountNumber.$model"
                  :disabled="bureauCustomer.isItemActioned"
                  :mask="['########']"
                  :guide="false"
                  ref="originatingAccountNumber"
                ></the-mask>
                <!-- Validation -->
                <span v-if="$v.bureauCustomer.accountNumber.$dirty">
                  <small id="accountNumberInputRequired"
                    class="form-text text-danger small text-nowrap"
                    v-if="!$v.bureauCustomer.accountNumber.required"
                  >Account Number is required</small>
                  <small id="accountNumberInputShort"
                    class="form-text text-danger small text-nowrap"
                    v-if="!$v.bureauCustomer.accountNumber.minLength"
                  >
                    Account Number must be at least
                    {{ $v.bureauCustomer.accountNumber.$params.minLength.min }}
                    numbers
                  </small>
                  <small id="accountNumberInputLong"
                    class="form-text text-danger small text-nowrap"
                    v-if="!$v.bureauCustomer.accountNumber.maxLength"
                  >
                    Account Number must be less than
                    {{ $v.bureauCustomer.accountNumber.$params.maxLength.max }}
                    numbers
                  </small>
                </span>
              </b-col>
              <span
                v-if="!(bureauCustomer.bankReference === '' && bureauCustomer.sortCode === '' && bureauCustomer.accountNumber === '')"
              >
                <b-col v-if="accountNumberValid === true">
                  <span id="accountNumberInputValid" style="font-size: 200%; color: green" class="fa fa-check-circle"></span>
                </b-col>
                <b-col v-else>
                  <small id="accountNumberInputLabelInvalid"
                    class="form-text text-warning small text-nowrap"
                    v-if="
                      !accountNumberValid &&
                      $v.bureauCustomer.accountNumber.required
                    "
                  >{{ accountNumberErrorMessage }}</small>
                </b-col>
              </span>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="bankNameInput">Bank Name</label>
              </b-col>
              <b-col sm="4">
                <b-form-input id="bankNameInput"
                  type="text"
                  :maxlength="50"
                  v-model.trim="$v.bureauCustomer.bankName.$model"
                  :disabled="bureauCustomer.isItemActioned"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="bankAddress1Input">Address</label>
              </b-col>
              <b-col sm="6">
                <b-form-input id="bankAddress1Input"
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.bankAddressLine1"
                  :disabled="bureauCustomer.isItemActioned"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="6" offset-sm="3">
                <b-form-input id="bankAddress2Input"
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.bankAddressLine2"
                  :disabled="bureauCustomer.isItemActioned"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="6" offset-sm="3">
                <b-form-input id="bankAddress3Input"
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.bankAddressLine3"
                  :disabled="bureauCustomer.isItemActioned"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="6" offset-sm="3">
                <b-form-input  id="bankAddress4Input"
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.bankAddressLine4"
                  :disabled="bureauCustomer.isItemActioned"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="bankAddressLocalityInput"></label>
              </b-col>
              <b-col sm="6">
                <b-form-input id="bankAddressLocalityInput"
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.bankAddressLocality"
                  :disabled="bureauCustomer.isItemActioned"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="bankAddressTownInput">Town</label>
              </b-col>
              <b-col sm="5">
                <b-form-input id="bankAddressTownInput"
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.bankAddressTown"
                  :disabled="bureauCustomer.isItemActioned"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="bankAddressCountyInput">County</label>
              </b-col>
              <b-col sm="5">
                <b-form-input id="bankAddressCountyInput"
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.bankAddressCounty"
                  :disabled="bureauCustomer.isItemActioned"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="bankAddressPostcodeInput">Post Code</label>
              </b-col>
              <b-col sm="2">
                <b-form-input id="bankAddressPostcodeInput"
                  type="text"
                  :maxlength="10"
                  v-model.trim="bureauCustomer.bankAddressPostcode"
                  :disabled="bureauCustomer.isItemActioned"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab id="limitsTab" title="Payment Limits">
            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="paymentLimitInput">Payment Limit</label>
              </b-col>
              <b-col sm="2">
                <currency-input id="paymentLimitInput"
                  type="text"
                  v-model="bureauCustomer.paymentLimit"
                  :disabled="bureauCustomer.isItemActioned"
                ></currency-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="submissionLimitInput">Submission Limit</label>
              </b-col>
              <b-col sm="2">
                <currency-input id="submissionLimitInput"
                  type="text"
                  v-model="bureauCustomer.submissionLimit"
                  :disabled="bureauCustomer.isItemActioned"
                ></currency-input>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab id="contactTab" title="Contact Info">
            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="contactFirstNameInput">Forename</label>
              </b-col>
              <b-col sm="4">
                <b-form-input id="contactFirstNameInput"
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.contactFirstName"
                  :disabled="bureauCustomer.isItemActioned"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="contactSurnameInput">Surname</label>
              </b-col>
              <b-col sm="4">
                <b-form-input id="contactSurnameInput"
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.contactSurname"
                  :disabled="bureauCustomer.isItemActioned"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2" v-if="addressFound === true">
              <b-col sm="3" class="mt-2">
                <label for="contactAddress1Input">Address</label>
              </b-col>
              <b-col sm="6">
                <b-form-input id="contactAddress1Input"
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.addressLine1"
                  :disabled="bureauCustomer.isItemActioned"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2" v-if="addressFound === true">
              <b-col sm="6" offset-sm="3">
                <b-form-input id="contactAddress2Input"
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.addressLine2"
                  :disabled="bureauCustomer.isItemActioned"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2" v-if="addressFound === true">
              <b-col sm="6" offset-sm="3">
                <b-form-input id="contactAddress3Input"
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.addressLine3"
                  :disabled="bureauCustomer.isItemActioned"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2" v-if="addressFound === true">
              <b-col sm="6" offset-sm="3">
                <b-form-input id="contactAddress4Input"
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.addressLine4"
                  :disabled="bureauCustomer.isItemActioned"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2" v-if="addressFound === true">
              <b-col sm="3" class="mt-2">
                <label for="contactAddressLocality"></label>
              </b-col>
              <b-col sm="6">
                <b-form-input id="contactAddressLocality"
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.addressLocality"
                  :disabled="bureauCustomer.isItemActioned"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2" v-if="addressFound === true">
              <b-col sm="3" class="mt-2">
                <label for="concatAddressTown">Town</label>
              </b-col>
              <b-col sm="5">
                <b-form-input id="concatAddressTown"
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.addressTown"
                  :disabled="bureauCustomer.isItemActioned"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2" v-if="addressFound === true">
              <b-col sm="3" class="mt-2">
                <label for="contactAddressCounty">County</label>
              </b-col>
              <b-col sm="5">
                <b-form-input id="contactAddressCounty"
                  type="text"
                  :maxlength="50"
                  v-model.trim="bureauCustomer.addressCounty"
                  :disabled="bureauCustomer.isItemActioned"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="concactAddressPostcode">Postcode</label>
              </b-col>
              <b-col sm="2">
                <b-form-input id="concactAddressPostcode"
                  type="text"
                  maxlength="10"
                  v-model.trim="bureauCustomer.addressPostcode"
                  :disabled="bureauCustomer.isItemActioned"
                ></b-form-input>
              </b-col>
              <b-col sm="2">
                <button id="concactAddressPostcodeLookupButton"
                  class="btn btn-info"
                  :disabled="
                    this.bureauCustomer.addressPostcode === '' ||
                    this.bureauCustomer.addressPostcode === null || bureauCustomer.isItemActioned
                  "
                  @click="getAddress()"
                >Lookup Address</button>
              </b-col>
            </b-row>

            <b-row class="mt-2" v-if="this.addressOptions.length > 0 && !this.addressFound">
              <b-col sm="3" class="mt-2">
                <label for="addressOptions">Addresses</label>
              </b-col>
              <b-col sm="8">
                <select id="addressOptions"
                  class="form-control"
                  aria-label="Please select an address"
                  v-model="selectedAddress"
                  @change="setAddress"
                >
                  <option selected>Please select an address</option>
                  <option
                    v-for="foundAddress in addressOptions"
                    :key="foundAddress.addressLine1"
                    :value="foundAddress"
                  >{{foundAddress.addressLine1}}, {{foundAddress.addressTown}}, {{foundAddress.addressCounty}}</option>
                </select>
              </b-col>
            </b-row>

            <hr />

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="contactTelephone">Telephone</label>
              </b-col>
              <b-col sm="3">
                <b-form-input id="contactTelephone"
                  type="text"
                  v-model.trim="bureauCustomer.telephone"
                  :disabled="bureauCustomer.isItemActioned"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="contactEmailAddress">Email</label>
              </b-col>
              <b-col sm="4">
                <b-form-input id="contactEmailAddress"
                  type="text"
                  v-model.trim="bureauCustomer.emailAddress"
                  :disabled="bureauCustomer.isItemActioned"
                ></b-form-input>
              </b-col>
            </b-row>

            <b-row class="mt-2">
              <b-col sm="3" class="mt-2">
                <label for="contactWebsiteUrl">Website</label>
              </b-col>
              <b-col sm="4">
                <b-form-input id="contactWebsiteUrl"
                  type="text"
                  v-model.trim="bureauCustomer.websiteUrl"
                  :disabled="bureauCustomer.isItemActioned"
                ></b-form-input>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab id="notesTab"
            title="Notes"
            @click="reloadNotes"
            v-if="customerId !== undefined && customerId !== null"
          >
            <b-row class="mt-2">
              <!-- <b-col sm="1" class="mt-2">
                <label for="bureauCustomer.notes">Notes</label>
              </b-col>-->
              <b-col>
                <bureau-customer-notes-control
                  ref="customerNotes"
                  :can-load="true"
                  :customer-id="customerId"
                />
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </div>

      <div v-if="ManageBureauCustomer" class="idb-block-footer">
        <button id="customerSaveButton"
          class="btn btn-primary"
          :disabled="saveDisabled
          || bureauCustomer.isItemActioned || currentSubmissionCount > 0 || nameInUse"
          @click="checkValidation() && onSaveClick()"
        >Save</button>
        <button id="customerDeleteButton"
          class="btn btn-danger float-right"
          :disabled="bureauCustomer.isItemActioned || currentSubmissionCount > 0 || inMapping"
          @click="onDeleteClick()"
        >Delete</button>
        <span id="inMappingLabel"
          class="text-warning float-right p-2"
          v-if="inMapping"
        >This customer cannot be deleted as it is used in a mapping</span>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import uuid from 'uuid/v4'
import swal from 'sweetalert2'
import { required, minLength, maxLength, helpers, maxValue, requiredIf } from 'vuelidate/lib/validators'
import { TheMask } from 'vue-the-mask'
import Utility from '@/Assets/Mixins/Utility'
import CurrencyInput from '@/Assets/Components/CurrencyInput.vue'
import colours from '@/Assets/Constants/colours'
import { mapGetters } from 'vuex'
import Ca from 'vuejs-datepicker/dist/locale/translations/ca'
import BureauCustomerNotesControl from '@/Components/Bureau/BureauCustomer/BureauCustomerNotesControl.vue'
const sunValidator = helpers.regex('alpha', /^\d{6}$/)

export default {
  components: {
    TheMask,
    CurrencyInput,
    BureauCustomerNotesControl
  },
  data () {
    return {
      BankDetailWarning: false,
      saveDisabled: true,
      sortCodeValid: false,
      accountNumberValid: false,
      bureauCustomer: {
        bureauCustomerId: '',
        name: '',
        customerReference: '',
        serviceUserNumber: '',
        defaultImportFilename: '[CUSTOMERREF].csv',
        defaultImportSchema: 'None',
        defaultImportMapping: '',
        contraNarrative: '',
        bankName: '',
        bankReference: '',
        sortCode: '',
        accountNumber: '',
        bankAddressLine1: '',
        bankAddressLine2: '',
        bankAddressLine3: '',
        bankAddressLine4: '',
        bankAddressLocality: '',
        bankAddressTown: '',
        bankAddressCounty: '',
        bankAddressPostcode: '',
        paymentLimit: 0.00,
        submissionLimit: 0.00,
        contactFirstName: '',
        contactSurname: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        addressLine4: '',
        addressLocality: '',
        addressTown: '',
        addressCounty: '',
        addressPostcode: '',
        telephone: '',
        websiteUrl: '',
        emailAddress: '',
        latitude: '',
        longitude: ''
      },
      addressOptions: [],
      addressBuildingNumber: '',
      selectedAddress: '',
      addressFound: true,
      action: { data: null },
      status: '',
      loading: true,
      newBureauCustomer: false,
      sortCodeErrorMessage: '',
      accountNumberErrorMessage: '',
      schemas: [],
      mappings: [],
      currentSubmissionCount: 0,
      inMapping: false,
      nameInUse: false,
      suppressValidate: true
      // ManageBureauCustomer: false
    }
  },
  computed: {
    ...mapGetters(['selectedCustomer']),
    paygateId () {
      return this.$store.getters.selectedCustomer !== undefined ? this.$store.getters.selectedCustomer : this.$store.state.common.customers.data[0].paygateId
    },
    currentPage () {
      return !this.hasCustomerId ? 'Create ' : 'Edit '
    },
    customerId () {
      return this.$route.params.id
    },
    hasCustomerId () {
      return this.$route.params.id && this.$route.params.id !== null && this.$route.params.id !== ''
    },
    ManageBureauCustomer () {
      return this.$store.getters.isInRole('ManageBureauCustomers')
    }
  },
  watch: {
    'bureauCustomer.bankReference': function () {
      this.runValidate()
    },
    'bureauCustomer.sortCode': function () {
      if(this.suppressValidate === false) {
        this.validate()
      }
    },
    'bureauCustomer.accountNumber': function () {
      if(this.suppressValidate === false) {
        this.validate()
      }
    },
    'bureauCustomer.serviceUserNumber': function () {
      this.runValidate()
    },
    'bureauCustomer.name': function () {
      this.runValidate()
    },
    selectedAddress: function () {
      this.populateAddress(this.selectedAddress)
    }
  },
  async created () {
    console.log('In manage bureau customers', this.$store.getters.isInRole('ManageBureauCustomers'))
    if (this.$route.params.id === undefined) {
      this.newBureauCustomer = true
      this.bureauCustomer.bureauCustomerId = uuid()
      this.bureauCustomer.paygateId = this.paygateId
      this.addressFound = false
    } else {
      await this.loadBureauCustomer(this.$route.params.id)
    }
    this.schemas = this.loadSchemas()
    this.schemas.push({ text: 'None', value: 'None' })
    this.mappings = await this.loadMappings('')
    await this.checkMappingUsage(this.$route.params.id)
    this.suppressValidate = false
  },
  mixins: [Utility],
  methods: {
    checkName: _.debounce(async function () {
      this.nameInUse = false
      let url = `${process.env.VUE_APP_BUREAU_API_URL}bureaucustomer/nameinuse`
      const response = await axios.get(url, { showload: true, params: { paygateId: this.paygateId, customerName: this.bureauCustomer.name, customerId: this.bureauCustomer.bureauCustomerId } })
      this.nameInUse = response.data
    }, 300),
    async checkMappingUsage (bureauCustomerId) {
      try {
        var response = await axios.get(`${process.env.VUE_APP_WORKFLOW_API_URL}mapping/bureaucustomerexists/${bureauCustomerId}`)
        this.inMapping = response.data.exists === true
      } catch (e) {
        console.log(e)
      }
    },
    async loadBureauCustomer (bureauCustomerId) {
      var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer/' + bureauCustomerId, { showload: true })
      try {
        this.bureauCustomer = response.data
        if (this.bureauCustomer.addressPostcode === '' || this.bureauCustomer.addressPostcode === null) {
          this.addressFound = false
        }
        this.runValidate()
      } catch (error) {
        console.error(error)
      }
      await this.getSubmissionCount()
    },
    async onSaveClick () {
      if (this.BankDetailWarning) {
        var result = result = await swal.fire({
          icon: 'warning',
          title: 'Save Customer',
          text: 'No bank details have been provided for this bureau customer. This bureau customer can still be used for processing but bank details will need to be provided at submission stage.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.success,
          confirmButtonText: 'Save',
          cancelButtonText: 'Cancel'
        })
        if (result.isConfirmed) {
          await this.save()
        }
      } else {
        await this.save()
      }
    },
    async save () {
      if (this.bureauCustomer.defaultImportSchema !== 'Mapping') {
        this.bureauCustomer.defaultImportMapping = ''
      }
      if (this.newBureauCustomer) {
        try {
          const response =
            await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer', this.bureauCustomer, { showload: true })
          this.newBureauCustomer = false
          if (response.data.status === 'Action') {
            this.$toastr.s(
              `Bureau Customer ${this.bureauCustomer.name} needs to be approved`,
              'Needs Approval'
            )
            this.$router.back()
          } else {
            this.$toastr.s(`Bureau Customer ${this.bureauCustomer.name} created`, 'Bureau Customer')
            this.$router.push({ name: 'BureauCustomerDetails', params: { id: response.data.id } })
            // this.loadBureauCustomer(this.$route.params.id)
          }
        } catch (error) {
          this.$toastr.e(`Failed to create ${this.bureauCustomer.name}`, 'Bureau Customer')
        }
      } else {
        try {
          const response = await axios.post(process.env.VUE_APP_BUREAU_API_URL + 'bureaucustomer/updatebureaucustomer', this.bureauCustomer, { showload: true })
          this.loadBureauCustomer(this.$route.params.id)

          if (response.data.status === 'Action') {
            this.$toastr.s(
              `Bureau Customer ${this.bureauCustomer.name} needs to be approved`,
              'Needs Approval'
            )
          } else {
            this.$toastr.s(`Successfully updated ${this.bureauCustomer.name}`, 'Bureau Customer')
          }
        } catch (error) {
          this.$toastr.e(`Failed to update ${this.bureauCustomer.name}`, 'Bureau Customer')
        }
      }
    },
    async getSubmissionCount () {
      try {
        const response = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureausubmission/getcountforcustomer/${this.bureauCustomer.bureauCustomerId}`, {
          showload: true,
          params: { paygateid: this.paygateId }
        })
        this.currentSubmissionCount = response.data
      } catch (e) {
        console.log('Could not get submission count for customer')
      }
    },
    async onDeleteClick () {
      // Go check first and see if it's in a job.
      var deleteCheck = await axios.get(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer/isBureauCustomerInJob/' + this.bureauCustomer.bureauCustomerId, { showload: true })
      var result
      if (deleteCheck.data.success === false) {
        // Get confirmation from the user that they really want to delete the customer
        result = await swal.fire({
          title: 'Delete Bureau Customer',
          icon: 'warning',
          html: `
            This bureau customer is still in the following jobs, the <b>job details will be deleted along with the bureau customer</b>
            <br/>
            <div class="row justify-content-md-left">
            <ul>
              ${deleteCheck.data.names.reduce((a, v) => a += `<li class="text-left">${v}</li>`, '')}
              </ul>
              </div>`,
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
      } else {
        // Get confirmation from the user that they really want to delete the customer
        result = await swal.fire({
          title: 'Delete Bureau Customer',
          text: 'Are you sure you want to delete this bureau customer',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: colours.danger,
          confirmButtonText: 'Yes',
          cancelButtonText: 'No'
        })
      }

      if (result.isConfirmed) {
        try {
          const response = await axios.delete(process.env.VUE_APP_BUREAU_API_URL + 'bureauCustomer/' + this.bureauCustomer.bureauCustomerId, { showload: true })
          console.log(response.data)
          if (response.data.status === 'Deleted') {
            this.$toastr.s(`Bureau Customer: "${this.bureauCustomer.name}" deleted`, 'Bureau Customer')
            this.$router.back()
          } else if (response.data.status === 'Action') {
            this.$toastr.s(`Bureau Customer: "${this.bureauCustomer.name}" deletion requires approval. An action has been created`, 'Bureau Customer')
          } else {
            this.$toastr.e(`Bureau Customer: "${this.bureauCustomer.name}" could not be deleted.`, 'Bureau Customer')
          }
        } catch (e) {
          this.$toastr.e('Could not delete Bureau Customer: "${this.bureauCustomer.name}".')
        }
      }
    },
    async getAddress () {
      this.addressFound = false
      var postcode = this.bureauCustomer.addressPostcode
      try {
        var response = await axios.get(process.env.VUE_APP_BUREAU_API_URL + `address/getaddress/${postcode}`, { showload: true })
        if (response.data.addresses.length === 0) {
          this.$toastr.w('Postcode Not Found', 'Sorry, we could not find that address')
        } else if (response.data.addresses.length === 1) {
          this.populateAddress(response.data.addresses[0])
          // this.foundAddresses = response.data.addresses[0]
        } else {
          this.addressOptions = response.data.addresses
          //          this.foundAddresses = response.data.addresses
        }
      } catch (e) {
        this.$toastr.w('Postcode Not Found', 'Sorry, we could not find that address')
      } finally {
        this.addressLookupInProgress = false
      }
    },
    setAddress () {
      if (this.selectedAddress != null) {
        this.bureauCustomer.addressLine1 = this.selectedAddress.addressLine1
        this.bureauCustomer.addressLine2 = this.selectedAddress.addressLine2
        this.bureauCustomer.addressLine3 = this.selectedAddress.addressLine3
        this.bureauCustomer.addressTown = this.selectedAddress.addressTown
        this.bureauCustomer.addressCounty = this.selectedAddress.addressCounty
        this.bureauCustomer.addressPostcode = this.bureauCustomer.addressPostcode.toUpperCase()
        this.addressFound = true
      }
    },

    populateAddress (data) {
      var address = data.split(',')
      this.bureauCustomer.addressLine1 = address[0]
      this.bureauCustomer.addressLine2 = address[1]
      this.bureauCustomer.addressLine3 = address[2]
      this.bureauCustomer.addressLine4 = address[3]
      this.bureauCustomer.addressLocality = address[4]
      this.bureauCustomer.addressTown = address[5]
      this.bureauCustomer.addressCounty = address[6]
      this.bureauCustomer.addressPostcode = this.bureauCustomer.addressPostcode.toUpperCase()
      this.addressFound = true
    },
    validate: _.debounce(async function () {
      const data = { sortCode: this.bureauCustomer.sortCode }

      if (this.bureauCustomer.accountNumber.length === 8) {
        data.accountNumber = this.bureauCustomer.accountNumber
      }
      if (!this.suppressValidate) {
        try {
          const validateUrl = process.env.VUE_APP_VALIDATE_API_URL + 'BankAccount'
          const response = await axios.get(validateUrl, {params: data}, {showload: true})

          this.validationResult = response.data
          this.sortCodeErrorMessage = ''
          this.accountNumberErrorMessage = ''
          if (this.validationResult.valid) {
            console.info('Sort code and account number are valid')
            this.sortCodeValid = true
            this.accountNumberValid = true
          } else {
            if (this.validationResult.errorCode === '1502') {
              console.info('Sort code is valid')
              this.sortCodeValid = true
              this.accountNumberValid = false
              this.accountNumberErrorMessage = this.validationResult.errorText
            } else {
              console.info('Sort code and account number are invalid')
              this.sortCodeValid = false
              this.sortCodeErrorMessage = this.validationResult.errorText
              this.accountNumberValid = false
              this.accountNumberErrorMessage = this.validationResult.errorText
            }
          }

          if (this.validationResult.valid || this.validationResult.errorCode === '1502') {
            this.bureauCustomer.bankName = this.validationResult.institutionName
            this.bureauCustomer.bankAddressLine1 = this.validationResult.branchTitle
            this.bureauCustomer.bankAddressLine2 = this.validationResult.address1
            this.bureauCustomer.bankAddressLine3 = this.validationResult.address2
            this.bureauCustomer.bankAddressLine4 = this.validationResult.address3
            this.bureauCustomer.bankAddressLine5 = this.validationResult.address4
            this.bureauCustomer.bankAddressTown = this.validationResult.town + this.validationResult.city
            this.bureauCustomer.bankAddressCounty = this.validationResult.county
            this.bureauCustomer.bankAddressPostcode = this.validationResult.postcode
          }
        } catch (e) {

        } finally {
          this.runValidate()
        }
      }
    }, 800),

    runValidate () {
      this.saveDisabled = true
      if (this.bureauCustomer.sortCode.length === 0 && this.bureauCustomer.accountNumber.length === 0 && this.bureauCustomer.bankReference.length === 0) {
        this.saveDisabled = this.$v.bureauCustomer.name.$invalid || this.$v.bureauCustomer.serviceUserNumber.$invalid
        this.BankDetailWarning = true
      } else {
        this.saveDisabled = this.$v.bureauCustomer.name.$invalid ||
          this.$v.bureauCustomer.serviceUserNumber.$invalid ||
          this.$v.bureauCustomer.sortCode.$invalid ||
          this.$v.bureauCustomer.accountNumber.$invalid ||
          this.$v.bureauCustomer.bankReference.$invalid
        this.BankDetailWarning = false
      }
    },
    reloadNotes () {
      if (this.$refs.customerNotes !== undefined) {
        this.$refs.customerNotes.load()
      }
    }
  },
  validations: {
    bureauCustomer: {
      serviceUserNumber: { minLength: 6, sunValidator, required },
      sortCode: {
        minLength: minLength(6),
        maxLength: maxLength(6),
        required: requiredIf((model) => {
          return !(model.accountNumber === '' && model.bankReference === '' && model.sortCode === '')
        })
      },
      accountNumber: {
        minLength: minLength(8),
        maxLength: maxLength(8),
        required: requiredIf((model) => {
          return !(model.accountNumber === '' && model.bankReference === '' && model.sortCode === '')
        })
      },
      bankName: {},
      bankReference: {
        required: requiredIf((model) => {
          return !(model.accountNumber === '' && model.sortCode === '' && model.bankReference === '')
        })
      },
      name: { required },
      defaultImportSchema: {},
      defaultImportMapping: {
        required: requiredIf((model) => {
          return model.defaultImportSchema === 'Mapping'
        })
      }
    }
  }
}

</script>
