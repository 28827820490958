<template>
  <div>
    <div class="idb-block">
      <div class="idb-block-title">
        <h2>
          Submissions In Progress
          <help-icon :docPath="'/bacsbureau/submissions/'"></help-icon>
          <favourite-icon></favourite-icon>
        </h2>
      </div>

      <div class="idb-block-content">
        <vue-good-table
          :paginationOptions="paginationOptions"
          :rows="rows"
          :columns="columns"
          :lineNumbers="true"
          @on-row-click="onRowClick"
          @on-cell-click="onCellClick"
          styleClass="vgt-table striped bordered"
          :searchOptions="{
                          enabled: true
                        }"
          :sort-options="sortOptions"
          mode="remote"
          :totalRows="totalRecords"
          @on-page-change="onPageChange"
          @on-per-page-change="onPerPageChange"
          @on-search="onSearch"
          @on-sort-change="onSortChange"
          ref="table"
          :isLoading.sync="isTableLoading"
        >
          <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field =='delete'">
              <span class="d-none">Cancel Submission</span>
            </span>
            <span v-else>{{props.column.label}}</span>
          </template>
          <template slot="table-row" slot-scope="props">
            <span
              v-if="props.column.field == 'delete' && !props.row.preventDelete && (!props.row.isLocked || props.row.editingUserId == userId)"
            >
              <button class="btn btn-danger" @on-cell-click="onCellClick" :disabled="isLoading">
                <i class="fa fa-trash mr-2"></i>Cancel Submission
              </button>
            </span>
            <span v-else-if="props.column.field == 'isLocked'">
              <i
                v-if="props.row.isLocked"
                class="fa fa-lock"
                v-b-popover.hover.top.d500="`Locked to: ${props.row.editingUserName}`"
              ></i>
            </span>
            <span v-else>
              <b-row>
                <b-col sm="12" class="mt-1">{{ props.formattedRow[props.column.field] }}</b-col>
              </b-row>
            </span>
          </template>
          <div slot="table-actions">
            <button
              @click.prevent="clearTableFilters"
              class="btn btn-link"
              v-b-popover.hover.top.d500="'Clear filters'"
            >
              <span class="fa-stack" style="font-size: 10px;">
                <i class="fa fa-filter fa-stack-1x dimmedIcon"></i>
                <i class="fa fa-ban fa-stack-2x dimmedIcon"></i>
              </span>
              <span class="d-none">Clear Filters</span>
            </button>
            <b-button
              @click.prevent="load"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Refresh the data in the table'"
            >
              <i class="fa fa-sync pointer dimmedIcon"></i>
              <span class="d-none">Refresh table</span>
            </b-button>
            <b-button
              @click.prevent="exportTable"
              class
              variant="link"
              v-b-popover.hover.top.d500="'Export the contents of the table'"
            >
              <i class="fa fa-file-export pointer dimmedIcon"></i>
              <span class="d-none">Export Table Contents</span>
            </b-button>
          </div>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import swal from 'sweetalert2'
import Utility from '@/Assets/Mixins/Utility'
import _ from 'lodash'
import tableFilterMixin from '@/Assets/Mixins/TableFilterMixin'
import loading from '@/Assets/Mixins/LoadingMixin'
import colours from '@/Assets/Constants/colours'
import bacsCommon from '@/Lib/BacsCommon.js'
import { returnRouteObject } from '@/Assets/Components/Actions/actionsHelper'
import papa from 'papaparse'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['selectedCustomer']),
    userId () {
      return this.$store.getters.getUserId
    }
  },
  mixins: [Utility, tableFilterMixin, loading],
  data () {
    return {
      rows: [],
      columns: [
        {
          label: 'Bureau Submission Id',
          field: 'bureauSubmissionId',
          hidden: true
        }, {
          label: 'Submission Reference',
          field: 'submissionReference'
        },
        {
          label: 'Job',
          field: 'bureauJobName'
        },
        {
          label: 'Group',
          field: 'groupName',
          formatFn: this.FormatGroup

        },
        {
          label: 'Created',
          field: 'createdDate',
          formatFn: this.formatDate
        },
        {
          label: 'Last Modification',
          field: 'lastUpdateDate',
          formatFn: this.formatDate
        },
        {
          label: 'Created By',
          field: 'createdByUsername'
        },
        {
          label: 'Status',
          field: 'status',
          formatFn: this.FormatStatus
        },
        {
          label: 'Locked',
          field: 'isLocked',
          tdClass: 'text-center'
        },
        {
          label: 'Editing User',
          field: 'editingUserName'
        },
        {
          field: 'delete',
          sortable: false,
          tdClass: 'text-center'
        }
      ],
      serverParams: {
        sort: [{ field: 'lastUpdateDate', type: 'desc' }]
      },
      totalRecords: 0
      // loading: false
    }
  },
  async created () {
    await this.GetBureauLicenseCheck()
  },
  methods: {
    load: _.debounce(async function () {
      try {
        this.isTableLoading = true

        var response = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureauSubmission/getpendingsubmissionsquery`, { params: { ...this.buildGoodTableQuery() }, showerror: true, errormessage: 'Failed loading submissions' })

        this.rows = response.data.data
        this.totalRecords = response.data.meta.totalItems
      } catch { } finally {
        this.isTableLoading = false
      }
    }, 500),
    async onRowClick (data) {
      switch (data.row.lastScreenState) {
        case 'BureauManualBacsAddInitialFiles':
          this.$router.push({ name: 'ManualBacsSubmissionAddFiles', params: { id: data.row.bureauSubmissionId } })
          break
        case 'BureauManualFPSAddInitialFiles':
          this.$router.push({ name: 'ManualBacsSubmissionAddFiles', params: { id: data.row.bureauSubmissionId } })
          break
        case 'BureauManualBacsImporting':
          this.$router.push({ name: 'ManualBacsImportPaymentFiles', params: { id: data.row.bureauSubmissionId, startProcess: false } })
          break
        case 'BureauManualFPSImporting':
          this.$router.push({ name: 'ManualBacsImportPaymentFiles', params: { id: data.row.bureauSubmissionId, startProcess: false } })
          break
        case 'BureauAutoBacsImporting':
          this.$router.push({ name: 'ImportPaymentFiles', params: { id: data.row.bureauSubmissionId, startProcess: false } })
          break
        case 'BureauAutoFPSImporting':
          this.$router.push({ name: 'ImportPaymentFiles', params: { id: data.row.bureauSubmissionId, startProcess: false } })
          break
        case 'BureauBacsViewPayments':
          this.$router.push({ name: 'PaymentDetails', params: { id: data.row.bureauSubmissionId } })
          break
        case 'BureauFPSViewPayments':
          this.$router.push({ name: 'PaymentDetails', params: { id: data.row.bureauSubmissionId } })
          break
        case 'BureauActionsPage':
          var actionUrl = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureausubmission/getaction/${data.row.bureauSubmissionId}`, { showload: true })

          if (actionUrl.data) {

            try {
              var actualActionCheck = await axios.get(`${process.env.VUE_APP_PLATFORM_API_URL}actions/${actionUrl.data.id}`)
              console.log(actualActionCheck)
              this.$router.replace(returnRouteObject(actionUrl.data))
            } catch {
              this.$toastr.e('You cannot progress this submission, since you don\'t have the necessary permissions', 'Unauthorized')
            }
          } else {
            this.$toastr.e('You cannot progress this submission, since you don\'t have the necessary permissions', 'Unauthorized')
          }
          break
      }

      // var readonly = data.row.preventDelete
      // if (readonly) {
      //   this.$router.push({ name: 'PaymentDetails', params: { id: data.row.bureauSubmissionId } })
      // } else {
      //   this.$router.push({ name: 'UploadPaymentFiles', params: { id: data.row.bureauSubmissionId } })
      // }
    },
    async exportTable () {
      try {
        var query = this.buildGoodTableQuery()
        query.perPage = this.totalRecords
        query.page = 1
        var response = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureauSubmission/getpendingsubmissionsquery`, {
          params: {
            ...query,
            paygateid: this.serverParams.paygateId,
            forExport: true
          },
          showload: true
        })
        if (response) {
          var csvString = papa.unparse(response.data)
          var blob = new Blob([csvString])
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, 'submissions-in-progress.csv')
          } else {
            var a = window.document.createElement('a')
            a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
            a.download = 'submissions-in-progress.csv'
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
          }
        }
      } catch {
        this.$toastr.e('Could not export schedule.')
      }
    },
    async deleteBureauSubmission (submissionId, submissionReference) {
      const response = await axios.delete(process.env.VUE_APP_BUREAU_API_URL + 'bureausubmission/deleteSubmission/' + submissionId, {})
      if (response.data.status === 'Deleted') {
        this.$toastr.s(`Bureau Submission ${submissionReference} deleted`, 'Bureau Submission')
        this.$store.dispatch('updateBureauTopSeverityLevel')
        this.$store.dispatch('updateBureauSubmissionPill')
        await this.load()
      }
    },
    async onCellClick (params) {
      if (params.column.field === 'delete' && (!params.row.preventDelete && (!params.row.isLocked || params.row.editingUserId === this.userId))) {
        const submissionId = params.row.bureauSubmissionId
        const submissionReference = params.row.submissionReference

        event.stopPropagation()        
          if (params.row.status === 'AwaitingApproval') {
            var nextStatus = 'Created'
            switch (params.row.bacsPaymentStatus) {
              case 'Created':
                nextStatus = 'Signed'
                break
              case 'Signed':
                nextStatus = 'Approved'
                break
              case 'Approved':
                nextStatus = 'Submitted'
                break
              case 'Cancelled':
                nextStatus = 'Cancelled'
            }

            if (nextStatus !== 'Cancelled') {
              var action = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureausubmission/getaction/${params.row.bureauSubmissionId}`, { showload: true })
              var json = JSON.stringify({
                submissionId: params.row.bureauSubmissionId,
                actionId: action.data.id,
                nextStatus: nextStatus
              })
              var canProceeedResponse = await axios({
                method: 'POST',
                url: process.env.VUE_APP_BACS_API_URL + 'bacs/submission/canActionProceed',
                data: json
              })
              console.log(canProceeedResponse.data)
              var cancelReponse = await bacsCommon.cancelSubmissionDialog()
              if (cancelReponse.cancel === true) {
                var group = await axios.get(`${process.env.VUE_APP_BUREAU_API_URL}bureausubmission/getbureaugroup/${params.row.groupId}`, { showload: true })

                json = JSON.stringify({
                  submissionId: params.row.bureauSubmissionId,
                  actionId: action.data.id,
                  serviceUserNumber: group.data.serviceUserNumber,
                  fileNumber: 0,
                  fromRecordNumber: 0,
                  toRecordNumber: 0,
                  deletePaymentData: group.data.deletePaymentData,
                  cancellationReason: cancelReponse.cancellationReason
                })
                var endTaskUrlCancel = process.env.VUE_APP_BACS_API_URL + 'bacs/submission/EndTask?submissionId=' + params.row.bureauSubmissionId
                await axios.get(endTaskUrlCancel)
                await this.deleteBureauSubmission(submissionId, submissionReference)
              }
            }
          } else {
            const swalResult = await swal.fire({
              title: 'Cancel Submission',
              text: `Are you sure you want to cancel the submission: ${submissionReference}`,
              icon: 'warning',
              showCancelButton: true,
              focusCancel: true,
              confirmButtonColor: colours.danger,
              confirmButtonText: 'Yes',
              cancelButtonText: 'No'
            })
            if (swalResult.isConfirmed) {
              await this.deleteBureauSubmission(submissionId, submissionReference)
            }
          }
      }
    },
    FormatStatus (field, row) {
      if (field === 'AwaitingApproval') {
        return 'Submission (' + row.bacsPaymentStatus + ')'
      } else { return field }
    },
    FormatGroup (field, row) {
      return row.groupName + ' (' + row.groupServiceUserNumber + ')'
    },
    formatDate (date) {
      if (!date.getDate) {
        date = new Date(date)
      }
      var day = date.getDate()
      var monthIndex = date.getMonth() + 1
      var year = date.getFullYear()
      var time = `${this.pad(date.getHours(), 2)}:${this.pad(date.getMinutes(), 2)} `
      return `${this.pad(day, 2)}/${this.pad(monthIndex, 2)}/${year} ${time}`
    },
    pad (num, size) {
      var s = '00' + num
      return s.substr(s.length - size)
    }
  },
  watch: {
    selectedCustomer () {
      this.$store.dispatch('getGroupsWithRights', { paygateId: this.paygateId })
      this.load()
    }
  }
}
</script>
