var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12" }, [
      _c("div", { staticClass: "idb-block" }, [
        _vm._m(0),
        _c("div", { staticClass: "idb-block-content" }, [
          _c("div", [
            _c("div", { staticClass: "row" }, [
              _vm._m(1),
              _c("div", { staticClass: "col-lg-3 col-sm-12" }, [
                _vm._v(_vm._s(_vm.truncate(_vm.workflow.metaData.title)))
              ]),
              _vm._m(2),
              _c("div", { staticClass: "col-lg-3 col-sm-12" }, [
                _vm._v(_vm._s(_vm.workflow.metaData.description))
              ]),
              _c(
                "div",
                { staticClass: "col-lg-2 col-sm-12" },
                [
                  _vm.workflowIsLoaded
                    ? _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-popover",
                              rawName: "v-b-popover.hover.top.d500",
                              value:
                                "Edit this workflow in the Workflow Designer.",
                              expression:
                                "'Edit this workflow in the Workflow Designer.'",
                              modifiers: { hover: true, top: true, d500: true }
                            }
                          ],
                          attrs: {
                            disabled: _vm.isLoading,
                            variant: "primary"
                          },
                          on: { click: _vm.btnEditWorkflow }
                        },
                        [
                          _c("i", { staticClass: "glyphicon ti-pencil mr-2" }),
                          _vm._v("Edit Workflow")
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ]),
            _c("br"),
            _c("div", { staticClass: "row" }, [
              _vm._m(3),
              _c("div", { staticClass: "col-md-3 col-sm-12" }, [
                _vm._v(_vm._s(_vm.reformatType(_vm.workflow.metaData.type)))
              ]),
              _vm._m(4),
              _c("div", { staticClass: "col-md-3 col-sm-12" }, [
                _vm._v(_vm._s(_vm.workflow.metaData.author))
              ])
            ]),
            _c("br")
          ])
        ])
      ]),
      _c("div", { staticClass: "idb-block" }, [
        _c("div", { staticClass: "idb-block-title" }, [
          _c("h2", [
            _c(
              "span",
              [
                _vm._v(" Workflow Runs "),
                _vm.heatmapLoading
                  ? _c("b-spinner", {
                      attrs: {
                        small: "",
                        variant: "secondary",
                        label: "Spinning"
                      }
                    })
                  : _vm._e(),
                _c(
                  "b-button",
                  {
                    directives: [
                      {
                        name: "b-popover",
                        rawName: "v-b-popover.hover.top.d500",
                        value: "Refresh the data in the heatmap",
                        expression: "'Refresh the data in the heatmap'",
                        modifiers: { hover: true, top: true, d500: true }
                      }
                    ],
                    attrs: { disabled: _vm.isLoading, variant: "link" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.getHeatmapData($event)
                      }
                    }
                  },
                  [_c("i", { staticClass: "fas fa-sync-alt pointer" })]
                )
              ],
              1
            )
          ])
        ]),
        _c("div", { staticClass: "idb-block-content" }, [
          _c(
            "div",
            [
              _c("calendar-heatmap", {
                attrs: {
                  "tooltip-unit": "executions",
                  endDate: _vm.getHeatmapEnddate(),
                  values: _vm.activityData,
                  "range-color": _vm.rangeColours
                },
                on: { "day-click": _vm.heatmapClick }
              })
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "idb-block" }, [
        _c("div", { staticClass: "idb-block-title" }, [
          _c(
            "h2",
            [
              _vm._v(" Workflow Log "),
              !_vm.showAll
                ? _c("span", [
                    _vm._v(" " + _vm._s(_vm.justDate(_vm.targetDay)) + " ")
                  ])
                : _vm._e(),
              _vm.vgtLoading
                ? _c("b-spinner", {
                    attrs: {
                      small: "",
                      variant: "secondary",
                      label: "Spinning"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ]),
        _c("div", { staticClass: "idb-block-content" }, [
          _c(
            "div",
            [
              _c(
                "vue-good-table",
                {
                  ref: "triggerTelemetry",
                  attrs: {
                    paginationOptions: _vm.paginationOptions,
                    "sort-options": _vm.sortOptions,
                    isLoading: _vm.isTableLoading,
                    rows: _vm.rows,
                    columns: _vm.columns,
                    mode: "remote",
                    totalRows: _vm.totalRecords,
                    lineNumbers: true,
                    styleClass: "vgt-table striped bordered table-hover"
                  },
                  on: {
                    "update:isLoading": function($event) {
                      _vm.isTableLoading = $event
                    },
                    "update:is-loading": function($event) {
                      _vm.isTableLoading = $event
                    },
                    "on-page-change": _vm.onPageChange,
                    "on-sort-change": _vm.onSortChange,
                    "on-column-filter": _vm.onColumnFilter,
                    "on-per-page-change": _vm.onPerPageChange,
                    "on-search": _vm.onSearch,
                    "on-row-click": _vm.onRowClick,
                    "on-cell-click": _vm.onCellClick
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "table-row",
                      fn: function(props) {
                        return [
                          props.column.field == "currentStatus"
                            ? _c(
                                "span",
                                [
                                  props.row.currentStatus == "Failed"
                                    ? _c(
                                        "b-badge",
                                        {
                                          attrs: { pill: "", variant: "danger" }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(props.row.currentStatus)
                                          )
                                        ]
                                      )
                                    : props.row.currentStatus == "Complete"
                                    ? _c(
                                        "b-badge",
                                        {
                                          attrs: {
                                            pill: "",
                                            variant: "success"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(props.row.currentStatus)
                                          )
                                        ]
                                      )
                                    : props.row.currentStatus ==
                                      "Completed with warnings"
                                    ? _c(
                                        "b-badge",
                                        {
                                          attrs: {
                                            pill: "",
                                            variant: "warning"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(props.row.currentStatus)
                                          )
                                        ]
                                      )
                                    : props.row.currentStatus == "Manual Action"
                                    ? _c(
                                        "b-badge",
                                        {
                                          attrs: {
                                            pill: "",
                                            variant: "warning"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(props.row.currentStatus)
                                          )
                                        ]
                                      )
                                    : props.row.currentStatus == "Processing"
                                    ? _c(
                                        "b-badge",
                                        {
                                          attrs: {
                                            pill: "",
                                            variant: "primary"
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(props.row.currentStatus)
                                          )
                                        ]
                                      )
                                    : _c("span", [
                                        _vm._v(_vm._s(props.row.currentStatus))
                                      ])
                                ],
                                1
                              )
                            : props.column.field == "createdAt"
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.reformatDate(
                                        props.formattedRow[props.column.field]
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : props.column.field == "updatedAt"
                            ? _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.reformatDate(
                                        props.formattedRow[props.column.field]
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      props.formattedRow[props.column.field]
                                    ) +
                                    " "
                                )
                              ])
                        ]
                      }
                    }
                  ])
                },
                [
                  _vm._v(
                    ' styleClass="vgt-table striped bordered table-hover"> '
                  ),
                  _c(
                    "div",
                    { attrs: { slot: "table-actions" }, slot: "table-actions" },
                    [
                      _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "b-popover",
                              rawName: "v-b-popover.hover.top.d500",
                              value: "Clear filters",
                              expression: "'Clear filters'",
                              modifiers: { hover: true, top: true, d500: true }
                            }
                          ],
                          staticClass: "btn btn-link",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.clearTableFilters($event)
                            }
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "fa-stack",
                              staticStyle: { "font-size": "10px" }
                            },
                            [
                              _c("i", {
                                staticClass:
                                  "fa fa-filter fa-stack-1x dimmedIcon"
                              }),
                              _c("i", {
                                staticClass: "fa fa-ban fa-stack-2x dimmedIcon"
                              })
                            ]
                          ),
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Clear filters")
                          ])
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-popover",
                              rawName: "v-b-popover.hover.top.d500",
                              value: "Show Today",
                              expression: "'Show Today'",
                              modifiers: { hover: true, top: true, d500: true }
                            }
                          ],
                          attrs: { disabled: _vm.isLoading, variant: "link" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.setShowToday($event)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "far fa-calendar pointer dimmedIcon"
                          }),
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Show Today")
                          ])
                        ]
                      ),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-popover",
                              rawName: "v-b-popover.hover.top.d500",
                              value: "Refresh the data in the table",
                              expression: "'Refresh the data in the table'",
                              modifiers: { hover: true, top: true, d500: true }
                            }
                          ],
                          attrs: { disabled: _vm.isLoading, variant: "link" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.load($event)
                            }
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fas fa-sync-alt pointer dimmedIcon"
                          }),
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Refresh Table")
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ]
              )
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "idb-block" }, [
        _vm._m(5),
        _c("div", { staticClass: "idb-block-content" }, [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c(
                "div",
                { staticClass: "col-md-2 col-sm-12" },
                [
                  _c(
                    "p-check",
                    {
                      staticClass: "p-switch",
                      attrs: { name: "check", color: "primary " },
                      model: {
                        value: _vm.workflow.createReport,
                        callback: function($$v) {
                          _vm.$set(_vm.workflow, "createReport", $$v)
                        },
                        expression: "workflow.createReport"
                      }
                    },
                    [_vm._v("Create a Workflow Report")]
                  )
                ],
                1
              ),
              _c("transition", { attrs: { name: "fadein" } }, [
                _vm.workflow.createReport
                  ? _c("div", { staticClass: "col-md-9 col-sm-12" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.workflow.reportEmailAddresses,
                            expression: "workflow.reportEmailAddresses"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          placeholder: "Email recipients (Comma Separated)",
                          rows: "4",
                          cols: "60"
                        },
                        domProps: { value: _vm.workflow.reportEmailAddresses },
                        on: {
                          input: [
                            function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.workflow,
                                "reportEmailAddresses",
                                $event.target.value
                              )
                            },
                            function($event) {
                              return _vm.$emit("textarea", $event.target.value)
                            }
                          ],
                          blur: function($event) {
                            return _vm.$v.workflow.reportEmailAddresses.$touch()
                          }
                        }
                      }),
                      !_vm.$v.workflow.reportEmailAddresses.required &&
                      _vm.$v.workflow.reportEmailAddresses.$dirty
                        ? _c("p", { staticClass: "validationText" }, [
                            _vm._v(
                              "This field is required because you have requested that a workflow report is sent via email!"
                            )
                          ])
                        : _vm._e(),
                      !_vm.$v.workflow.reportEmailAddresses.maxLength
                        ? _c("p", { staticClass: "validationText" }, [
                            _vm._v(
                              "Maximum length is " +
                                _vm._s(
                                  _vm.$v.workflow.reportEmailAddresses.$params
                                    .maxLength.max
                                ) +
                                " characters!"
                            )
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ])
            ],
            1
          )
        ])
      ]),
      _c("div", { staticClass: "idb-block" }, [
        _c("div", { staticClass: "idb-block-title" }, [
          _c("h2", [
            _vm._v("Triggers using this Workflow "),
            _c("span", { staticClass: "badge badge-pill badge-primary" }, [
              _vm._v(_vm._s(_vm.boundTriggers.length))
            ])
          ])
        ]),
        _c(
          "div",
          { staticClass: "idb-block-content" },
          _vm._l(_vm.boundTriggers, function(trigger, index) {
            return _c("div", { key: index, staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-md-12" },
                [
                  trigger.triggerType == "cronTrigger"
                    ? _c("i", { staticClass: "far fa-clock" })
                    : trigger.triggerType == "fileTrigger"
                    ? _c("i", { staticClass: "far fa-file" })
                    : trigger.triggerType == "scheduleTrigger"
                    ? _c("i", { staticClass: "far fa-calendar-alt" })
                    : _vm._e(),
                  (trigger.triggerType = "cronTrigger")
                    ? _c(
                        "router-link",
                        {
                          attrs: {
                            tag: "a",
                            to:
                              "/automation/workflow/triggerconfig/cronTrigger/" +
                              trigger.id
                          }
                        },
                        [_vm._v(" " + _vm._s(trigger.title) + " ")]
                      )
                    : _vm._e(),
                  trigger.enabled == true
                    ? _c("span", { staticClass: "muted small" }, [
                        _vm._v(" Enabled")
                      ])
                    : _c("span", { staticClass: "muted small" }, [
                        _vm._v(" Disabled")
                      ])
                ],
                1
              )
            ])
          }),
          0
        )
      ]),
      _c("div", { staticClass: "idb-block" }, [
        _c("div", { staticClass: "idb-block-title" }, [
          _c(
            "h2",
            [
              _vm._v("Workflow Metrics "),
              _vm.workflowMetricsLoading
                ? _c("b-spinner", {
                    attrs: {
                      small: "",
                      variant: "secondary",
                      label: "Spinning"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ]),
        _c("div", { staticClass: "idb-block-content" }, [
          _c("div", { staticClass: "row" }, [
            _vm._m(6),
            _c("div", { staticClass: "col-md-3" }, [
              !_vm.workflowMetrics.numExecuted
                ? _c("span", [_vm._v("None")])
                : _c("span", [_vm._v(_vm._s(_vm.workflowMetrics.numExecuted))])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _vm._m(7),
            _c("div", { staticClass: "col-md-3" }, [
              _vm.workflowMetrics.avgQueueTime
                ? _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.millisecToSec(_vm.workflowMetrics.avgQueueTime)
                      ) + " Seconds"
                    )
                  ])
                : _vm._e()
            ]),
            _vm._m(8),
            _c("div", { staticClass: "col-md-3" }, [
              _vm.workflowMetrics.avgExecutionTime
                ? _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.millisecToSec(_vm.workflowMetrics.avgExecutionTime)
                      ) + " Seconds"
                    )
                  ])
                : _vm._e()
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _vm._m(9),
            _c("div", { staticClass: "col-md-3" }, [
              !_vm.workflow.creationDate
                ? _c("span", [_vm._v("-")])
                : _c("span", [
                    _vm._v(_vm._s(_vm.reformatDate(_vm.workflow.creationDate)))
                  ])
            ]),
            _vm._m(10),
            _c("div", { staticClass: "col-md-3" }, [
              !_vm.workflow.lastExecutionDate
                ? _c("span", [_vm._v("-")])
                : _c("span", [
                    _vm._v(
                      _vm._s(_vm.reformatDate(_vm.workflow.lastExecutionDate))
                    )
                  ])
            ])
          ]),
          _c("br")
        ]),
        _c(
          "div",
          { staticClass: "idb-block-footer" },
          [
            _vm.workflowIsLoaded
              ? _c(
                  "b-button",
                  {
                    staticClass: "mr-3",
                    attrs: { disabled: _vm.isLoading, variant: "primary" },
                    on: { click: _vm.btnEditWorkflow }
                  },
                  [
                    _c("i", {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Edit this workflow in the Workflow Designer.",
                          expression:
                            "'Edit this workflow in the Workflow Designer.'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      staticClass: "glyphicon ti-pencil mr-2"
                    }),
                    _vm._v("Edit Workflow")
                  ]
                )
              : _vm._e(),
            _vm.workflowIsLoaded
              ? _c(
                  "b-button",
                  {
                    staticClass: "mr-3",
                    attrs: {
                      disabled: _vm.isLoading,
                      variant: "outline-primary"
                    },
                    on: { click: _vm.btnSaveWorkflow }
                  },
                  [
                    _c("i", {
                      directives: [
                        {
                          name: "b-popover",
                          rawName: "v-b-popover.hover.top.d500",
                          value: "Save this workflow.",
                          expression: "'Save this workflow.'",
                          modifiers: { hover: true, top: true, d500: true }
                        }
                      ],
                      staticClass: "glyphicon ti-save mr-2"
                    }),
                    _vm._v("Save Details")
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "btn-group ", attrs: { role: "group" } }, [
              _vm._m(11),
              _c(
                "div",
                {
                  staticClass: "dropdown-menu",
                  attrs: { "aria-labelledby": "btnGroupDrop1" }
                },
                [
                  _vm.workflowIsLoaded
                    ? _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: { href: "#" },
                          on: { click: _vm.btnCloneWorkflow }
                        },
                        [
                          _c("i", { staticClass: "glyphicon ti-files mr-2" }),
                          _vm._v("Clone Workflow")
                        ]
                      )
                    : _vm._e(),
                  _vm.isCorvidAdmin
                    ? _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: { href: "#" },
                          on: { click: _vm.btnTransferWorkflow }
                        },
                        [
                          _c("i", {
                            staticClass: "glyphicon ti-new-window mr-2"
                          }),
                          _vm._v("Copy Workflow to Another Customer")
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "dropdown-divider" }),
                  _vm.workflowIsLoaded
                    ? _c(
                        "a",
                        {
                          staticClass: "dropdown-item text-danger",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.btnDeleteWorkflow($event)
                            }
                          }
                        },
                        [
                          _c("i", { staticClass: "glyphicon ti-trash mr-2" }),
                          _vm._v("Delete Workflow")
                        ]
                      )
                    : _vm._e()
                ]
              )
            ])
          ],
          1
        )
      ]),
      _c("br")
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [
        _vm._v("Workflow Detail"),
        _c("span", { staticClass: "pull-right" }, [
          _c("a", { attrs: { href: "#", target: "_blank" } }, [
            _c("i", { staticClass: "far fa-question-circle" })
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-lg-2 col-sm-12" }, [
      _c("strong", [_vm._v("Title")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-lg-2 col-sm-12" }, [
      _c("strong", [_vm._v("Description")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2 col-sm-12" }, [
      _c("strong", [_vm._v("Workflow Type")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2 col-sm-12" }, [
      _c("strong", [_vm._v("Author")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "idb-block-title" }, [
      _c("h2", [_vm._v("Workflow Report ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-3" }, [
      _c("p", { staticClass: "font-weight-bold" }, [
        _vm._v("Number of Executions")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-3" }, [
      _c("p", { staticClass: "font-weight-bold" }, [
        _vm._v("Average Queue Time")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-3" }, [
      _c("p", { staticClass: "font-weight-bold" }, [
        _vm._v("Average Execution Time")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-3" }, [
      _c("p", { staticClass: "font-weight-bold" }, [_vm._v("Workflow Created")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-3" }, [
      _c("p", { staticClass: "font-weight-bold" }, [_vm._v("Last Execution")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-outline-primary dropdown-toggle",
        attrs: {
          id: "btnGroupDrop1",
          type: "button",
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false"
        }
      },
      [_c("i", { staticClass: "glyphicon ti-more mr-2" })]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }